import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { Basket, BasketType } from 'src/app/models/basket';
import { BasketComponentState } from 'src/app/models/basket_component_state';
import { NotificationType } from 'src/app/models/notification';
import { ChangeState, ResetModifiedBaskets } from 'src/app/store/actions/basket-component.actions';
import { Create, Delete, ECollectionItemType, Update } from 'src/app/store/actions/collection.actions';
import { ShowNotification } from 'src/app/store/actions/notifications.actions';
import { selectBasketComponentState, selectFrameBaskets, selectModifiedBaskets } from 'src/app/store/selectors/basket-component.selectors';
import { AppState } from 'src/app/store/state/app.state';

@Component({
  selector: 'basket-actions',
  templateUrl: './basket-actions.component.html',
  styleUrls: ['./basket-actions.component.scss']
})
export class BasketActionsComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  @ViewChild("basketNameInput", {static: false}) basketNameInput: ElementRef;
  private selectedFrameBaskets: Basket[];
  private state: BasketComponentState;
  private modifiedBaskets: Record<number, Basket>;

  private selectedFrameBaskets$ : Observable<Basket[]> = this.store.pipe(select(selectFrameBaskets));
  private state$ : Observable<BasketComponentState> = this.store.pipe(select(selectBasketComponentState));
  private modifiedBaskets$ : Observable<Record<number, Basket>> = this.store.pipe(select(selectModifiedBaskets));
  
  constructor(
    private store : Store<AppState>
  ) { }

  ngOnInit() {
    this.subscriptions.push(
      this.selectedFrameBaskets$.subscribe(basket => {
        this.selectedFrameBaskets = basket;
      })
    );
    this.subscriptions.push(
      this.state$.subscribe(state => {
        this.state = state;
      })
    );
    this.subscriptions.push(
      this.modifiedBaskets$.subscribe(baskets => {
        this.modifiedBaskets = baskets;
      })
    );
  }

  public onMerge() {
    const newBasketName : string = this.basketNameInput.nativeElement.value;
    if (!newBasketName || newBasketName == "") {
      this.store.dispatch(new ShowNotification("Enter a basket name", NotificationType.Information));
      return;
    }
    const allIds =
    _
    .chain(this.selectedFrameBaskets)
    .flatMap(basket => basket.videoFrameIds)
    .uniq()
    .value();

    const newBasket : Basket = {
      name: newBasketName,
      type: BasketType.VideoFrames,
      videoFrameIds: allIds
    }
    this.store.dispatch(new Create(newBasket, ECollectionItemType.Basket));
  }

   public editBaskets() {
     this.store.dispatch(new ChangeState(BasketComponentState.Editing));
   }

   public applyBasketChanges() {
     for(let basket of Object.values(this.modifiedBaskets)) {
      this.store.dispatch(new Update(basket, ECollectionItemType.Basket));
     }
     this.store.dispatch(new ChangeState(BasketComponentState.Initial));
   }

   public cancelBasketChanges() {
    this.store.dispatch(new ResetModifiedBaskets());
    this.store.dispatch(new ChangeState(BasketComponentState.Initial));
  }

  public deleteBasket() {
    const result = window.confirm(`You are about to delete ${this.selectedFrameBaskets.length} basket(s). Are you sure?`);
    if (!result) {
      return;
    }
    console.log('deleteBasket: selected baskets', this.selectedFrameBaskets);
    for(let basket of this.selectedFrameBaskets) {
      this.store.dispatch(new Delete(basket, ECollectionItemType.Basket));
    }
  }
}

import Tag from './tag';
import * as CryptoJS from 'crypto-js';

export default class FileToUpload {
	file: File;
	date: Date;
	comment: string;
	tags: Tag[];
	progress: number;
	error?: string;


	hash : string;

	/*
	set file(value : File) {
		this._file = value;
		this.hash = null
	} 
	
	get file() : File) {
		return this._file;
	} 
	*/ 

	
}



/**
 * This function produces a user side hash
 *
 * For performace sake we hash first megabyte. We could hash several chunks if there would be problems
 *
 **/
export const getHash = async (file : FileToUpload) : Promise<string> => {
  if (file.hash != null)
    return file.hash;

  const filePart = file.file.slice(0, Math.min(1000000, file.file.size));

  let readerPromise = new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onerror = function() {
            console.log('FileReader Onload seem to fail');
            reject();
        }
        fr.onload = function() {
            console.log('FileReader Onload seem to load array');
            resolve(fr.result);
        }
        fr.readAsArrayBuffer(filePart);
  });

  let sha256 = CryptoJS.algo.SHA256.create();

  /* Should chain Promises */

  let array = <ArrayBuffer> await readerPromise;
  console.log('Array in sync ' + typeof(array));

  var wordArray = CryptoJS.lib.WordArray.create(array);
  sha256.update(wordArray);
  file.hash = sha256.finalize().toString();
  console.log('Hash: ' + file.hash);
  return file.hash;
}
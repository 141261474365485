import { initialBackendConfigurationState } from '../state/app.state';
import { BackendConfigurationActions, EBackendConfigurationActions } from '../actions/backend-configuration.actions';
import { BackendConfiguration } from 'src/app/models/backend_configuration';

export function backendConfigurationReducers( 
	state = initialBackendConfigurationState,
	action: BackendConfigurationActions
) : BackendConfiguration {
	switch (action.type) {
		case EBackendConfigurationActions.LoadSuccess:
			return action.config;
		default:
			return state;
	}
}
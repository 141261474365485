import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/store/state/app.state';
import { LoadJobs } from 'src/app/store/actions/jobs.actions';
import { Job } from 'src/app/models/job';
import { selectFullJobs } from 'src/app/store/selectors/jobs.selectors';
import { ChangeJob } from 'src/app/store/actions/annotator-component.actions';
import { NavigateToTab } from 'src/app/store/actions/contents-tab-component.actions';

@Component({
  selector: 'my-jobs-tab',
  templateUrl: './my-jobs-tab.component.html',
  styleUrls: ['./my-jobs-tab.component.scss']
})
export class MyJobsTabComponent implements OnInit, OnDestroy {
  selectedStatus?: number = null;
  jobs: Job[] = [];

  private jobs$: Observable<Job[]> = this.store.pipe(select(selectFullJobs));
  private subscriptions: Subscription[] = [];
  private numberOfTagsToShow = 2;

  constructor(private store: Store<AppState>) { }

  ngOnInit() {
    this.subscriptions.push(
      this.jobs$.subscribe(jobs => {
        this.jobs = jobs;
        console.log('my-jobs-tab: new jobs:', this.jobs.length);
      })
    );
    this.store.dispatch(new LoadJobs());
  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  switchToStatus(statusNumber?: number) {
    this.selectedStatus = statusNumber;
  }
  
  getJobsToShow() {
    if (this.selectedStatus === null) {
      return this.jobs;
    }
    else {
      return this.jobs.filter(el => el.status === this.selectedStatus);
    }
  }

  selectJob(job: Job) {
    this.store.dispatch(new ChangeJob(job.id));
    this.store.dispatch(new NavigateToTab(6)); // Navigate to Segments tab
  }

  getJobThumbnailUrl(job: Job) {
    const filteredFrames = job.frame_basket.videoFrames.filter(f => f);
    if (filteredFrames.length > 0) {
      if (filteredFrames[0].thumbnail_url) {
        return filteredFrames[0].thumbnail_url;
      }
      else {
        return filteredFrames[0].url;
      }
    }

    return null;
  }
}

export enum NotificationType {
  Information = 0,
  Success = 1,
  Error = 2
}

export class Notification {
  id   : string;
  text : string;
  type : NotificationType;
  date : Date;
}
import { AppState } from "../state/app.state";
import { createSelector } from '@ngrx/store';
import { Basket } from 'src/app/models/basket';
import { BasketsSelectors } from './baskets.selectors';
import { BasketComponentState } from "src/app/models/basket_component_state";
import { ConstentsItemType, ContentsItem } from "src/app/models/contents_item";
import * as _ from "lodash";

const getSelectedFrameBasketIds = (state: AppState) => state.basketComponent.frameBasketIds;
const getState = (state: AppState) => state.basketComponent.state;
const getModifiedBaskets = (state: AppState) => state.basketComponent.modifiedBaskets;

const getBasketContentItems = (basket: Basket) : ContentsItem[] =>
  basket.videoFrames
  .filter(frame => frame)
  .map(frame => ({
    id: frame.id,
    title: frame.name,
    url: frame.url,
    thumbnail_url: frame.thumbnail_url ? frame.thumbnail_url : frame.url,
    thumbnail_hover_url: frame.thumbnail_url ? frame.thumbnail_url : frame.url,
    type: ConstentsItemType.Frame,
    group_key: null
  }));

export const selectFrameBasketIds = createSelector(
  getSelectedFrameBasketIds,
  (ids : number[]) => ids);

export const selectBasketComponentState = createSelector(
  getState,
  (state : BasketComponentState) => state);

export const selectFrameBaskets = createSelector(
  BasketsSelectors.instance.selectBasketsWithFramesDict,
  getSelectedFrameBasketIds,
  (baskets : Record<number, Basket>, basketIds: number[]) =>
    basketIds.map((basketId) => baskets[basketId])
);

export const selectModifiedBaskets = createSelector(
  getModifiedBaskets,
  (baskets) => baskets
)

export const selectFrameBasketsWithItems = createSelector(
  BasketsSelectors.instance.selectBasketsWithFramesDict,
  getModifiedBaskets,
  (baskets : Record<number, Basket>, modifiedBaskets: Record<number, Basket>) : Record<number, Basket> =>
    _.mapValues(baskets, (basket, basketId) => {
      // @ts-ignore
      const foundModifiedBasket = modifiedBaskets[basketId];
      if (foundModifiedBasket) {
        return {
          ...basket,
          ...foundModifiedBasket,
          items: getBasketContentItems(foundModifiedBasket)
        };
      }
      else {
        return {
          ...basket,
          items: getBasketContentItems(basket)
        };
      }
     })
);
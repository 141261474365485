import { Component, OnInit, Input } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid-community';
import Tag from 'src/app/models/tag';
import { TagService } from 'src/app/services/tag.service';
import FileToUpload from 'src/app/models/file_to_upload';
import { Auth } from 'aws-amplify';

@Component({
  selector: 'tags-cell-renderer',
  templateUrl: './tags-cell-renderer.component.html',
  styleUrls: ['./tags-cell-renderer.component.scss']
})
export class TagsCellRendererComponent implements OnInit, ICellRendererAngularComp {
  availableTags : Tag[] = [];
  rowData: FileToUpload;

  dropdownSettings = {
    singleSelection: false,
    text: "Select tags",
    enableSearchFilter: true,
    addNewItemOnFilter: true,
    labelKey: "name",
    searchBy: ['name']
  }

  constructor(
    private tagService: TagService
  ) { }

  ngOnInit() {
    this.tagService.getTags('', 100).subscribe(tags => {
      this.availableTags = tags;
    });
  }

  refresh(params: any): boolean {
	  return true;
	}
	agInit(params: ICellRendererParams): void {
    this.rowData = params.data;
	}
	afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
  }
  
  async onAddItem(value: string): Promise<void> {
    const newTag: Tag = {id: null, name: value, color: '0', user_id: null, popualrity: 0};
    this.rowData.tags.push(newTag);

    const currentUser = await Auth.currentAuthenticatedUser();
    newTag.user_id = currentUser.username;
  }

}

import { Component, OnInit } from '@angular/core';

import { Job }                 from 'src/app/models/job';
import { JobService }   from 'src/app/services/jobs.service';

import * as _ from 'lodash';

@Component({
  selector: 'debug-jobs',
  templateUrl: './debug-jobs.component.html',
  styleUrls: ['./debug-jobs.component.scss']
})

export class DebugJobsComponent implements OnInit {

  jobs        : Job[] = [];

  constructor(
    private jobService    : JobService
  ) { }


  ngOnInit(): void {
    this.refresh();
  }

  refreshJobs() : void {
    this.refresh();
  }

  refresh() {
     this.jobService.getJobs()
    .subscribe(data => {
      this.jobs = data;
      console.log(data);
    });
  }

  jobsSelected(idx:number) : void {
    let id = this.jobs[idx].id;   
  }

}

import { Injectable } from '@angular/core';
import { HttpParams, HttpErrorResponse } from '@angular/common/http';
import { HttpAuthClient } from './http_auth';
import { Observable } from 'rxjs';
import { Auth } from 'aws-amplify';
import { Store } from '@ngrx/store';

import { Actor, ActorTarget }     from '../models/actor';
import ActionStatus  from '../models/action_status';
import UploadedFile  from '../models/uploaded_file';
import { AppState } from '../store/state/app.state';
import { HttpServiceBase } from './http_service_base';
import { VideoFrame } from '../models/video_frame';
import { Basket } from '../models/basket';


@Injectable({
  providedIn: 'root'
})
export class ActionsService extends HttpServiceBase {
  constructor(
    protected httpAuth: HttpAuthClient,
    protected store : Store<AppState>
  ) {
    super(store);
  }

  getActors(target: ActorTarget, internal: boolean) : Observable<Actor[]> {
    console.log('serverUrl [getActors]:', this.serverUrl);
    let params = new HttpParams();
    if (target != null) {
      params = params.append("target", target);
    }
    if (internal != null) {
      params = params.append("internal", internal.toString());
    }

    return this.httpAuth.get<Actor[]>(`${this.serverUrl}/api/actors/`, {params : params})
    .map(res => {
      console.log('serverUrl [getActors]: result: ', res);
      return res;
    });
  }

  getActionStatus() : Observable<ActionStatus[]> {
    return this.doRequest<any>((url, options, body) => this.httpAuth.get(url), '/api/action/status').map(result => result.data );
  }

  getActionStatusEx(user : string, showFinished : boolean, idSort : number, contentIdSort : number, statusSort: number) : Observable<ActionStatus[]> {
    // @ts-ignore
    let params = new HttpParams();
    if (showFinished) {
      params = params.append("showFinished", showFinished.toString());
    }
    params = params.append("idSort", idSort.toString());
    params = params.append("contentIdSort", contentIdSort.toString());
    params = params.append("statusSort", statusSort.toString());

    return this.doRequest<any>((url, options, body) => this.httpAuth.get(url), '/api/action/status', {params : params}).map(result => result.data );
  }

  getActionStatusEx1(filters: any, sorts: any[], limit: number, offset: number) : Observable<ActionStatus[]> {
    // @ts-ignore

    let sort_request: string = "";
    for (let i = 0; i < sorts.length; i++)
    {
      let sort = sorts[i];
      sort_request += ((i == 0) ? '' : ',') + sort.name + ':' + sort.order.toString();
    }    

    let params = new HttpParams();
    if (sort_request !== "") {
      params = params.append("sort", sort_request);
    }

    if (limit > 0) {
      params = params.append("limit", limit.toString());
    }    
    if (offset > 0) {
      params = params.append("offset", offset.toString());
    }

    for (let i = 0; i < filters.length; i++)
    {
      let filter = filters[i];
      params = params.append(filter.name, filter.value);
    }    

    //return this.doRequest<ActionStatus[]>((url, options, body) => this.httpAuth.get(url), '/api/action/status', {params : params});
    return this.httpAuth.get<any>(`${this.serverUrl}/api/action/status`, {params : params}).map(result => result.data );
  }

  getActionsUsers() : Observable<string[]> {
    return this.doRequest<string[]>((url, options, body) => this.httpAuth.get(url), '/api/action/users');
  }

  startVideoActions(actor: Actor, files: UploadedFile[]) : Observable<ActionStatus[]> {
    console.log("startVideoActions", actor, "for", files);
    return Observable
    .fromPromise(Auth.currentAuthenticatedUser())
    .switchMap((currentUser) => {
      const body = {
        files: files.map((f) => { return {id: f.id, fileName: f.fileName} }),
        content_type: ActorTarget.Video,
        actor       : actor.id,
        user        : currentUser
      };

      return this.httpAuth.post<ActionStatus[]>(`${this.serverUrl}/api/action/create`, body, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
    });
  }

  startFrameActions(actor: Actor, files: VideoFrame[]) : Observable<ActionStatus[]> {
    console.log("startFrameActions", actor, "for", files);
    return Observable
    .fromPromise(Auth.currentAuthenticatedUser())
    .switchMap((currentUser) => {
      const body = {
        content_type: ActorTarget.Frame,        
        files: files.map((f) => { return {id: f.id, fileName: f.url} }),
        actor : actor.id,
        user : currentUser
      };

      return this.httpAuth.post<ActionStatus[]>(`${this.serverUrl}/api/action/create`, body, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
    });
  }

  startBasketActions(actor: Actor, baskets: Basket[]) : Observable<ActionStatus[]> {
    console.log("startBasketActions", actor, "for", baskets);
    return Observable
    .fromPromise(Auth.currentAuthenticatedUser())
    .switchMap((currentUser) => {
      const body = {
        content_type: ActorTarget.Basket,  
        files: baskets.map((f) => { return {id: f.id, fileName: "basket"} }),
        actor : actor.id,
        user : currentUser
      };

      return this.httpAuth.post<ActionStatus[]>(`${this.serverUrl}/api/action/create`, body, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
    });
  }

  async cancelActions(actions: ActionStatus[]) : Promise<HttpErrorResponse> {
    console.log("cancelActions", actions);
    try {
      Auth.currentAuthenticatedUser()
        .then(async currentUser => {
        await this.httpAuth.post(
          `${this.serverUrl}/api/action/cancel`,
          {
            actions: actions.map(a => a.id)
          },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }).toPromise();
        });
    }
    catch(e) {
      return e;
    }
    return null;
  }
}

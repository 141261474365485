import { Injectable } from '@angular/core';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, concatMap, withLatestFrom } from 'rxjs/operators';
import { EContentsTabComponentActions, FramesFiltersChanged, NavigateToFramesPage, NavigateToVideoPage, VideoFiltersChanged } from '../actions/contents-tab-component.actions';
import { ECollectionItemType, LoadPage } from '../actions/collection.actions';
import { VideoFrame } from 'src/app/models/video_frame';
import { Store } from '@ngrx/store';
import { AppState } from '../state/app.state';
import { selectCurrentFrameFilters, selectCurrentVideoFilters } from '../selectors/contents-tab-component.selectors';
import { filter } from 'lodash';
import { FilterPair } from 'src/app/models/filter_pair';
import UploadedFile from 'src/app/models/uploaded_file';

const PAGE_SIZE = 50;

@Injectable()
export class ContentsTabComponentEffects {
	constructor(
		private store: Store<AppState>,
		private actions$: Actions
	) {}

	@Effect()
	navigateToFramesPage$ = this.actions$.pipe(
		ofType<NavigateToFramesPage>(EContentsTabComponentActions.NavigateToFramesPage),
		concatMap(action => of(action).pipe(
			withLatestFrom(this.store.select(selectCurrentFrameFilters))
		)),
		switchMap(([action, filters]) => {
			let fieldFilters : FilterPair[] = [];
			if (filters.statusFilter !== null && filters.statusFilter !== undefined) {
				fieldFilters.push({
					name: 'status',
					value: filters.statusFilter.toString()
				})
			}

			return of(new LoadPage<VideoFrame>(
				ECollectionItemType.VideoFrame,
				filters.sortColumn,
				filters.sortOrder,
				action.pageNumber,
				PAGE_SIZE,
				PAGE_SIZE * action.pageNumber,
				filters.searchQuery,
				fieldFilters));
			}
		))

	@Effect()
	filterFrames$ = this.actions$.pipe(
		ofType<FramesFiltersChanged>(EContentsTabComponentActions.FramesFiltersChanged),
		switchMap(action => of(new NavigateToFramesPage(0))
	))

	@Effect()
	navigateToVideoPage$ = this.actions$.pipe(
		ofType<NavigateToVideoPage>(EContentsTabComponentActions.NavigateToVideoPage),
		concatMap(action => of(action).pipe(
			withLatestFrom(this.store.select(selectCurrentVideoFilters))
		)),
		switchMap(([action, filters]) => {
			let fieldFilters : FilterPair[] = [];

			return of(new LoadPage<UploadedFile>(
				ECollectionItemType.Video,
				filters.sortColumn,
				filters.sortOrder,
				action.pageNumber,
				PAGE_SIZE,
				PAGE_SIZE * action.pageNumber,
				filters.searchQuery,
				fieldFilters));
			}
		))

	@Effect()
	filterVideos$ = this.actions$.pipe(
		ofType<VideoFiltersChanged>(EContentsTabComponentActions.VideoFiltersChanged),
		switchMap(action => of(new NavigateToVideoPage(0))
	))
}
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Configuration } from '../models/configuration';
import { AppConfiguration } from '../../config.js';

import { BackendConfiguration } from '../models/backend_configuration';
import { HttpServiceBase } from './http_service_base';
import { Store } from '@ngrx/store';
import { HttpAuthClient } from './http_auth';
import { AppState } from '../store/state/app.state';

@Injectable({
	providedIn: 'root'
})
export class ConfigurationService extends HttpServiceBase {
	
	constructor(
		private httpAuth: HttpAuthClient,
    protected store : Store<AppState>
	) {
		super(store);
	}

	getConfiguration() : Observable<Configuration> {
		return of(AppConfiguration);
	}

	getBackendConfiguration() : Observable<BackendConfiguration> {
		let result =
			this.httpAuth.get<BackendConfiguration>(`${this.serverUrl}/open/configuration/`, {});

		return result.pipe(
			catchError((err) => {
				console.log("Failed to get backend configuration", err);
				return of({
					backendAvailable: false,
					backendVersion : null,
					dbVersion      : null
				});
			}),
			map(config => ({
				backendAvailable: true,
				...config
			})));
	}
}
import { VideoFilters } from "src/app/models/video_filters";
import { VideoFrameFilters } from "src/app/models/video_frame_filters";

export interface ContentsTabComponentState {
  selectedFrames: number[], // Selected frame client ids
  selectedVideos: number[], // Selected video client ids
  selectedTabNumber: number, // Selected tab in vertical tab list
  framesPage: number,
  totalFramePages: number,
  framesFilters: VideoFrameFilters,
  frameFiltersShown: boolean,
  pageFrameIds: number[],
  totalVideoPages: number,
  videoFilters: VideoFilters,
  videoFiltersShown: boolean,
  videosPage: number,
  pageVideoIds: number[]
}

export const initialContentsTabComponentState : ContentsTabComponentState = {
  selectedFrames: [],
  selectedVideos: [],
  selectedTabNumber: 0,
  framesPage: 0,
  totalFramePages: 0,
  framesFilters: new VideoFrameFilters(),
  frameFiltersShown: false,
  pageFrameIds: [],
  totalVideoPages: 0,
  videoFilters: new VideoFilters,
  videoFiltersShown: false,
  videosPage: 0,
  pageVideoIds: []
};
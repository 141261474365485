import { Component, OnInit } from '@angular/core';

import UploadedFile        from 'src/app/models/uploaded_file';
import { Actor }           from 'src/app/models/actor';
import { UploadChunk }     from 'src/app/models/upload_chunk';


import { UploadsService }       from 'src/app/services/uploads.service';
import { ActionsService }       from 'src/app/services/actions.service';



import * as _ from 'lodash';

@Component({
  selector: 'debug-video-tools',
  templateUrl: './debug-video-tools.component.html',
  styleUrls: ['./debug-video-tools.component.scss']
})



export class DebugVideoToolsComponent implements OnInit {

  uploadedFiles: UploadedFile[] = [];
  actors       : Actor[] = [];
  videoUsers   : string[] = ["Mal", "Jane", "Inara", "Kaylee"];

  selectedActor : number = 0;

  selectedUser  : string = "";
  showDeleted   : boolean  = false;

  checkedFiles  : UploadedFile[] = [];

  constructor(
    private uploadsService    : UploadsService,
    private actionsService    : ActionsService,
  ) { }

  /* Present helper functions */
  getSimpleSize(size: number) : string
  {
    if (size > 1024 * 1024 * 1024) {
        let s = size / (1024 * 1024 * 1024);
        return Math.round(s).toString() + " Gb";
    }

    if (size > 1024 * 1024) {
      let s = size / (1024 * 1024);
      return Math.round(s).toString() + " Mb";
    }

    if (size > 1024) {
      let s = size / 1024;
      return Math.round(s).toString() + " Kb";
    }
    return size.toString + " bytes";
  }

  getSimpleDate(inVal: string) : string 
  {
    const date : Date =  new Date(Date.parse(inVal.replace(' ', 'T')));
    return date.toLocaleDateString(); 
  }

  getSimpleTime(inVal: string) : string 
  {
    const date : Date =  new Date(Date.parse(inVal.replace(' ', 'T')));
    return date.toLocaleTimeString(); 
  }


  actorSelected(event: any): void
  {
    console.log(event);
    this.selectedActor = event.target.selectedIndex - 1;
  }

  videoUserSelected(name : string) : void
  {
      this.selectedUser = name;
  }

  filterVideos() : void {
    this.refresh();
  }

  ngOnInit(): void {
    this.refresh();
  }

  onCheckChanged(file: UploadedFile, event: any) : void {
    if (event.target.checked) {
      this.checkedFiles.push(file);
    }
    else {
      _.remove(this.checkedFiles, (f) => f === file);
    }
  }

  onShowDeletedChanged(event : any) : void {
    this.showDeleted = event.target.checked;
  }

  async deleteCheckedFiles(){
    const error = await this.uploadsService.deleteUploads(this.checkedFiles);
    if (error) {
      console.error(error);
      alert(error.message);
    }
    this.refresh();
    this.checkedFiles = [];
  }

  async startActionCheckedFiles() {
    console.log(this.selectedActor);
    if (this.selectedActor <= 0)
      return;

    let actor = this.actors[this.selectedActor];
    this.actionsService
    .startVideoActions(actor, this.checkedFiles)
    .subscribe((res) => null, (error) => {
      console.error(error);
      alert(error.message);
    })
    this.refresh();
    this.checkedFiles = [];
  }


  sort : any = {
    id          : 2,
    size        : 1,
    upload_date : 1
  }

   onIdSort() : void
   {
     this.sort.id = (this.sort.id + 1) % 3;
   }

   onSizeSort() : void
   {
     this.sort.size = (this.sort.size + 1) % 3;
   }

   onUploadDateSort() : void
   {
     this.sort.upload_date = (this.sort.upload_date + 1) % 3;

   }



   refresh() {
    this.uploadsService.getUploadsEx(
      [
        {name: 'user'    , value: this.selectedUser}, 
        {name: 'deleted' , value: this.showDeleted}
      ], 
      [ 
        {name: 'id'          , order: this.sort.id          }, 
        {name: 'size'        , order: this.sort.size        },
        {name: 'upload_date' , order: this.sort.upload_date} 
      ], 0, 0
    )
    .subscribe(data => {
      this.uploadedFiles = data;
      /*So far make it here, then it would go to db*/
      for (let videoFile of this.uploadedFiles) {
        if (! videoFile.thumbnail_url) {
           videoFile.thumbnail_url="assets/images/video-player.svg";
        }
      }
      console.log(data);
    });

    this.uploadsService.getUploadUsers()
    .subscribe(data => {
      this.videoUsers = data;
      console.log(data);
    });

    this.actionsService.getActors(null, true)
    .subscribe(data => {
      this.actors = data;
      if (data.length > 0) {
        this.selectedActor = -1;
      }
      console.log(data);
    });

  }

  thumbnailChange(event: any, state:number, video_info:any ) : void
  {
    /*console.log(state.toString + "  " + event);*/
    if (state == 1) {
      event.target.src = (video_info.thumbnail_video_url != null) ? video_info.thumbnail_video_url : video_info.thumbnail_url     ;
    } 
    if (state == 0) {
      event.target.src = (video_info.thumbnail_url       != null) ? video_info.thumbnail_url       : video_info.thumbnail_video_url;
    }    
  }


  /* Chunks */
  chunks: UploadChunk[] = [];
  chunkVideo : number = -1;

  updateChunks() : void {
    console.log("updateChunks(): called " + this.chunkVideo);

    this.uploadsService.getChunks(this.chunkVideo)
    .subscribe(data => {this.chunks = data});
  }

  onVideoChanged(event:any) : void {
    this.chunkVideo = event.target.value;
  }

  getColor(status:number) : string {
    if  (status == 0) return "#000000";
    if  (status == 1) return "#FF0000";
    if  (status == 2) return "#8F8F8F";
    return "#00FF00";
  }


}

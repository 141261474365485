import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import { HttpAuthClient } from './http_auth';
import { Job } from '../models/job';
import { HttpServiceBase } from './http_service_base';
import { AppState } from '../store/state/app.state';
import { Auth } from 'aws-amplify';

@Injectable({
	providedIn: 'root'
})
export class JobService extends HttpServiceBase {
	constructor(
		private httpAuth: HttpAuthClient,
		protected store : Store<AppState>
	) {
		super(store);
	}

	getJobs() : Observable<Job[]> {
    return Observable
    .fromPromise(Auth.currentAuthenticatedUser())
    .switchMap((currentUser) => {   
      console.log('currentUser', currentUser);
      return this.httpAuth.get<Job[]>(`${this.serverUrl}/api/jobs?email=${currentUser.attributes.email}`);
    });
	}

  createJob(job: Job) : Observable<Job> {
    return Observable
    .fromPromise(Auth.currentAuthenticatedUser())
    .switchMap((currentUser) => {
      const newJob = {
        description: job.description,
        user_id: currentUser.username,
        frame_basket_id: job.frame_basket_id,
        assigned_to: job.assigned_to
      }
      console.log('new job:', newJob);

      return this.httpAuth.post<Job>(`${this.serverUrl}/api/jobs/`, newJob, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
    });
  }

  updateJob(job: Job) : Observable<Job> {
    return Observable
    .fromPromise(Auth.currentAuthenticatedUser())
    .switchMap((currentUser) => {
      return this.httpAuth.put<Job>(`${this.serverUrl}/api/jobs/`, job, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
    });
  }

}
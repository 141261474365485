import { Configuration } from '../../models/configuration';
import { initialConfigurationState } from '../state/configuration.state'
import { ConfigurationActions, EConfigurationActions } from '../actions/configuration.actions'

export function configurationReducers( 
	state = initialConfigurationState,
	action: ConfigurationActions
) : Configuration {
	switch (action.type) {
		case EConfigurationActions.LoadConfigurationSuccess:
			return {
				... state,
				... action.configuration
			}
		default:
			return state;
	}
}
import { Action } from '@ngrx/store';
import { Job } from 'src/app/models/job';

export enum EJobsActions {
	LoadJobs = '[Jobs] Load',
  LoadJobsSuccess = '[Jobs] Load Success',
  CreateJob = '[Jobs] Create',
	CreateJobSuccess = '[Jobs] Create Success',
	UpdateJob = '[Jobs] Update',
	UpdateJobSuccess = '[Jobs] Update Success'
}

export class LoadJobs implements Action {
	public readonly type = EJobsActions.LoadJobs;
}

export class LoadJobsSuccess implements Action {
	public readonly type = EJobsActions.LoadJobsSuccess;
	constructor ( public jobs: Job[] ) {}
}

export class CreateJob implements Action {
  public readonly type = EJobsActions.CreateJob;
  constructor ( public job: Job ) {}
}

export class CreateJobSuccess implements Action {
	public readonly type = EJobsActions.CreateJobSuccess;
	constructor ( public job: Job ) {}
}

export class UpdateJob implements Action {
  public readonly type = EJobsActions.UpdateJob;
  constructor ( public job: Job ) {}
}

export class UpdateJobSuccess implements Action {
	public readonly type = EJobsActions.UpdateJobSuccess;
	constructor ( public job: Job ) {}
}

export type JobsActions = 
	LoadJobs | LoadJobsSuccess | CreateJob | CreateJobSuccess | UpdateJob | UpdateJobSuccess;
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AppState } from 'src/app/store/state/app.state';
import { Store, select } from '@ngrx/store';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import ObjectOntologyClass from 'src/app/models/object_ontology_class';
import * as _ from 'lodash';
import { OntologySelectors } from 'src/app/store/selectors/collection.selectors';

class Node {
  ontologyClass: ObjectOntologyClass;
  children?: Node[];
  isSelected: boolean = false;
}

@Component({
  selector: 'ontology-tree',
  templateUrl: './ontology-tree.component.html',
  styleUrls: ['./ontology-tree.component.scss']
})
export class OntologyTreeComponent implements OnInit {
  @Output("onClassSelected") onClassSelected = new EventEmitter<ObjectOntologyClass>();

  private ontology$ = this.store.pipe(select(OntologySelectors.selectItems));
  treeControl = new NestedTreeControl<Node>(node => node.children);
  dataSource = new MatTreeNestedDataSource<Node>();
  classes: Node[] = [];
  visibleClasses : Node[] = [];
  allNodesDict : _.Dictionary<Node>;

  constructor(private store : Store<AppState>) {
    this.dataSource.data = [];
  }

  hasChild = (_: number, node: Node) => !!node.children && node.children.length > 0;

  ngOnInit() {
    this.ontology$.subscribe((ontology) => {
      this.allNodesDict = _
        .chain(ontology)
        .map(c => <Node>{
          ontologyClass: c,
          children: []
        })
        .keyBy(c => c.ontologyClass.id)
        .value();

      for (let key in this.allNodesDict) {
        const element = this.allNodesDict[key];
        if (element.ontologyClass.parent_id >= 0) {
          const parent = this.allNodesDict[element.ontologyClass.parent_id];
          if (parent) {
            parent.children.push(element);
          }
        }
      }

      // Class - leave element of a tree
      // Class cannot be on a top level
      this.classes = _
        .chain(this.allNodesDict)
        .values()
        .filter(el => el.ontologyClass.isLeaf)
        .value();

      // Subcategory - parent of a classe
      const subcategories = _
        .chain(this.classes)
        .map(el => this.allNodesDict[el.ontologyClass.parent_id])
        .uniq()
        .filter(el => !!el)
        .value();

      const rootcategories = _
        .chain(this.allNodesDict)
        .values()
        .filter(el => el.ontologyClass.parent_id < 0)
        .value();

      for(const el of subcategories) {
        el.children = el.children.filter(c => this.classes.indexOf(c) < 0);
      }

      this.dataSource.data = rootcategories;
    });
  }

  private getAllClasses(node: Node) : Node[] {
    return this.classes
      .filter(c => c.ontologyClass.parent_id === node.ontologyClass.id)
      .concat(
        _.flatMap(node.children, c => c.children.length === 0 ? [] : this.getAllClasses(c)));
  }

  getPathString(node: Node): string {
    const path = node.ontologyClass.path;
    if (!path || path.length <= 2) {
      return "";
    }
    else {
      return path.slice(0, path.length - 2).join('/');
    }
  }

  getParentName(node: Node): string {
    if (node.ontologyClass.parent_id < 0) {
      return "";
    }
    else {
      const parent = this.allNodesDict[node.ontologyClass.parent_id];
      return parent.ontologyClass.name;
    }
  }

  onCategoryClick(node: Node) {
    for (const node of _.values(this.allNodesDict)) {
      node.isSelected = false;
    }
    node.isSelected = true;

    this.visibleClasses = this.getAllClasses(node);
  }

  onClassClick(node: Node) {
    for (const node of this.visibleClasses) {
      node.isSelected = false;
    }
    node.isSelected = true;
    this.onClassSelected.emit(node.ontologyClass);
  }

}

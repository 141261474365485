import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import UIkit from 'uikit';

import UploadedFile        from 'src/app/models/uploaded_file';
import { Actor }           from 'src/app/models/actor';
import ActionStatus        from 'src/app/models/action_status';

import { environment }          from 'src/environments/environment';
import { Auth } from 'aws-amplify';

import { UploadsService }       from 'src/app/services/uploads.service';
import { ActionsService }       from 'src/app/services/actions.service';


import { DOCUMENT } from '@angular/common';
import * as _ from 'lodash';
import ActiveActor from 'src/app/models/active_actors';
import ProxyStat   from 'src/app/models/proxy_stat';

import { ActorsProxyService } from 'src/app/services/actors_proxy.service';

@Component({
  selector: 'admin-actors-proxy',
  templateUrl: './admin-actors-proxy.component.html',
  styleUrls: ['./admin-actors-proxy.component.scss']
})



export class AdminActorsProxyComponent implements OnInit {
 
  serverUrl: string;
  activeActors : ActiveActor[] = [];
  images : string[];

  actorsStats : ProxyStat[] = [];
  

  constructor(  
    private actorsProxyService    : ActorsProxyService
  ) { }
  
  ngOnInit(): void {
    this.serverUrl = environment.serverUrl;

    this.refresh();
  }

  refresh() {
    this.actorsProxyService.getActiveActors()
    .subscribe(async data => {
      this.activeActors = data;
      console.log(data);

      this.updateImageURL();
    });

    this.actorsProxyService.getProxyStats("0") 
    .subscribe(async data => {
      this.actorsStats = data;     
    });
  }


  async updateImageURL()  {
    Auth.currentSession().then(
      async currentSession => {
        const authtoken = currentSession.getAccessToken().getJwtToken();

        let url =  this.serverUrl + "/proxy/bins/?jwt=" + authtoken + "&" + "target=0";
        console.log("image url", url);
        this.images = [url, url];
      }
    );
    this.images = [];
  }

}

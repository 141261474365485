import { Video } from "aws-sdk/clients/rekognition";
import * as moment from "moment";
import { SortOrder } from "./sort_order";
import UploadedFile from "./uploaded_file";

export enum VideoSortColumn {
  Id = 'id',
  CreationDate = 'creation_date'
}

export class VideoFilters {
  sortColumn: VideoSortColumn = VideoSortColumn.CreationDate;
  sortOrder: SortOrder = SortOrder.Desc;
  searchQuery?: string = null
  tagFilter?: string = null;
  creationDateFilter?: string = null;

  getSortColumnKeyValue = (video: UploadedFile) => {
    switch (this.sortColumn) {
      case VideoSortColumn.CreationDate:
        return moment(video.upload_date).format('YYYY-MM-DD');
      case VideoSortColumn.Id:
        return video.id;
      default:
        return null;
    }
  }
}
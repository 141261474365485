export let utilsConvert = {   
      /* Present helper functions */

      
  getSimpleSize: (size: number) : string =>
  {
    if (!size) 
    {
      return "((null))";
    }

    if (size > 1024 * 1024 * 1024) {
        let s = size / (1024 * 1024 * 1024);
        return Math.round(s).toString() + " Gb";
    }

    if (size > 1024 * 1024) {
      let s = size / (1024 * 1024);
      return Math.round(s).toString() + " Mb";
    }

    if (size > 1024) {
      let s = size / 1024;
      return Math.round(s).toString() + " Kb";
    }
    return size.toString + " bytes";
  },

  getSimpleDate: (inVal: string) : string =>
  {
    if (!inVal) 
    {
      return "((null))";
    }
    const date : Date =  new Date(Date.parse(inVal.replace(' ', 'T')));
    return date.toLocaleDateString(); 
  },

  getSimpleTime: (inVal: string) : string =>
  {
    if (!inVal) 
    {
      return "((null))";
    }
    const date : Date =  new Date(Date.parse(inVal.replace(' ', 'T')));
    return date.toLocaleTimeString(); 
  },

  getColorCode: (color : number) : string =>
  {
    return "#" + (color & 0x00FFFFFF).toString(16).padStart(6, '0')
  },

  getPrintableHash : (hash : any) : string => 
  {
    if (!hash) {
      return "(null)";
    }

    if (typeof hash === 'string') {
      return hash.substring( 0, 16) + "<br/>" + 
             hash.substring(16, 32) + "<br/>" + 
             hash.substring(32, 48) + "<br/>" + 
             hash.substring(48    ); 
    } else {
      return hash.toString();
    }

  }


};

import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'debug-sorter-renderer',
  templateUrl: './debug-sorter-renderer.component.html',
  styleUrls: ['./debug-sorter-renderer.component.scss']
})
export class DebugSorterRendererComponent implements OnInit {
 
  sort = {
    id: 0
  }

  constructor(   
  ) { }

  ngOnInit() {
  }

  refresh(params: any): boolean {
	  return true;
	}
  

}

import { Action } from '@ngrx/store';

export enum EAppActions {
	NavigateToTab = '[App] NavigateToTab'
}

export class NavigateToTab implements Action {
  public readonly type = EAppActions.NavigateToTab;
  constructor(public tabNumber: number) {}
}

export type AppActions = NavigateToTab;
import { AppState } from "../state/app.state";
import { createSelector } from '@ngrx/store';
import { selectFullJobsDict } from './jobs.selectors';
import { Job } from 'src/app/models/job';

const getJobId = (state: AppState) => state.annotatorComponent.jobId;
const getCurrentFrameIndex = (state: AppState) => state.annotatorComponent.currentFrameIndex;

export const selectJobId = createSelector(
  getJobId,
  (jobId : number) => jobId
);

export const selectJob = createSelector(
  selectFullJobsDict,
  getJobId,
  (jobs : Record<number, Job>, jobId: number) =>
    jobId === null
    ? null
    : jobs[jobId]
);

export const selectCurrentFrameIndex = createSelector(
  getCurrentFrameIndex,
  (index : number) => index
);
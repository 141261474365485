import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { UploadxModule } from 'ngx-uploadx';
import { HttpClientModule } from '@angular/common/http';
import { AgGridModule } from 'ag-grid-angular';

import { AppComponent } from './components/app/app.component';
import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import { HeaderComponent } from './components/header/header.component';
import { ContentsTabComponent } from './components/contents-tab/contents-tab.component';
import { PeopleTabComponent } from './components/people-tab/people-tab.component';
import { UploadDialogComponent } from './components/upload-dialog/upload-dialog.component';

import { ContentsItemListComponent } from './components/contents-item-list/contents-item-list.component';
import { VideosActionsComponent } from './components/videos-actions/videos-actions.component';

import { DebugTabComponent }        from './components/debug-tab/debug-tab.component';
import { DebugOntologiesComponent } from './components/debug-ontologies/debug-ontologies.component';
import { DebugFramesComponent }     from './components/debug-frames/debug-frames.component';
import { DebugVideosComponent }     from './components/debug-videos/debug-videos.component';
import { DebugVideoToolsComponent } from './components/debug-video-tools/debug-video-tools.component';
import { DebugActionsComponent }    from './components/debug-actions/debug-actions.component';
import { DebugBasketsComponent }    from './components/debug-baskets/debug-baskets.component';
import { DebugJobsComponent }       from './components/debug-jobs/debug-jobs.component';
import { DebugMLComponent }         from './components/debug-ml/debug-ml.component';
import { DebugTagsComponent }       from './components/debug-tags/debug-tags.component';
import { DebugAuthComponent }       from './components/debug-auth/debug-auth.component';
import { DebugSorterRendererComponent } from './components/debug-sorter-renderer/debug-sorter-renderer.component';
import { AdminActorsProxyComponent } from './components/admin-actors-proxy/admin-actors-proxy.component';


import { FramesActionsComponent } from './components/frames-actions/frames-actions.component';
import { AnnotatorComponent } from './components/annotator/annotator.component';
import { BasketComponent } from './components/basket/basket.component';
import { BasketActionsComponent } from './components/basket-actions/basket-actions.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { TagsCellRendererComponent } from './components/tags-cell-renderer/tags-cell-renderer.component';
import { CutterComponent } from './components/cutter/cutter.component';
import { CutterActionsComponent } from './components/cutter-actions/cutter-actions.component';
import { appReducers } from './store/reducers/app.reducers';
import { ConfigurationEffects } from './store/effects/configuration.effects';
import { MyJobsTabComponent } from './components/my-jobs-tab/my-jobs-tab.component';
import { OntologyTabComponent } from './components/ontology-tab/ontology-tab.component';
import { OntologyTreeComponent } from './components/ontology-tree/ontology-tree.component';
import { OntologyEffects } from './store/effects/ontology.effects';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { BasketsEffects } from './store/effects/baskets.effects';
import { JobsEffects } from './store/effects/jobs.effects';
import { FramesEffects } from './store/effects/frames.effects';
import { NotificationsEffects } from './store/effects/notifications.effects';
import { VideosEffects } from './store/effects/videos.effects';
import { ActionsComponent } from './components/actions/actions.component';
import { FrameLabelsEffects } from './store/effects/frame-labels.effects';
import { ContentsTabComponentEffects } from './store/effects/contents-tab-component.effects';
import { BackendConfigurationEffects } from './store/effects/backend-configuration.effects';
import { FiltersPanelComponent } from './components/filters-panel/filters-panel.component';
import { ContentsItemLineComponent } from './components/contents-item-line/contents-item-line.component';
import { CrowdAwsAnnotatorComponent } from './components/crowd-aws-annotator/crowd-aws-annotator.component';
import { MainComponent } from './components/main/main.component';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { SafePipe } from './pipes/safe.pipe';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ContentsTabComponent,
    PeopleTabComponent,
    UploadDialogComponent,
    ContentsItemListComponent,
    VideosActionsComponent,
    DebugTabComponent,
    DebugOntologiesComponent,
    DebugVideosComponent,
    DebugVideoToolsComponent,
    DebugFramesComponent,
    DebugActionsComponent,
    DebugBasketsComponent,
    DebugJobsComponent,
    DebugMLComponent,
    DebugTagsComponent,
    DebugAuthComponent,
    DebugSorterRendererComponent,
    AdminActorsProxyComponent,
    FramesActionsComponent,
    AnnotatorComponent,
    BasketComponent,
    BasketActionsComponent,
    TagsCellRendererComponent,
    CutterComponent,
    CutterActionsComponent,
    MyJobsTabComponent,
    OntologyTabComponent,
    OntologyTreeComponent,
    ActionsComponent,
    FiltersPanelComponent,
    ContentsItemLineComponent,
    CrowdAwsAnnotatorComponent,
    MainComponent,
    SafePipe
  ],
  imports: [
    BrowserModule,
    StoreModule.forRoot(appReducers),
    EffectsModule.forRoot([
      ConfigurationEffects,
      OntologyEffects,
      BasketsEffects,
      VideosEffects,
      FramesEffects,
      JobsEffects,
      FrameLabelsEffects,
      NotificationsEffects,
      ContentsTabComponentEffects,
      BackendConfigurationEffects
    ]),
    StoreDevtoolsModule.instrument(),
    UploadxModule,
    HttpClientModule,
    AmplifyUIAngularModule,
    FormsModule,
    AgGridModule.withComponents([
      TagsCellRendererComponent
    ]),
    AngularMultiSelectModule,
    BrowserAnimationsModule,
    MatTreeModule,
    MatIconModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class AppModule { }

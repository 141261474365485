import { Injectable } from '@angular/core';

import { Observable, from } from 'rxjs';
import 'rxjs/add/operator/switch';

import { Auth } from 'aws-amplify';
import { utilsAuth } from './utils_auth';

import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class HttpAuthClient {
  constructor(private http: HttpClient) { }

  /** ************************************
   *   helpers
   ************************************ **/
  addToken(options : any, authtoken : string) : any
  {
    if (!options) {
      options = {};
    }
    if (!options.headers) { 
        options.headers = {};      
    }
    /* This is a sudo like mechanism */
    let toDrop = utilsAuth.getDroppedPrivelegeArray();
    if (toDrop) {
      options.headers['x-hawk-dropto'] = JSON.stringify(toDrop);
    }
    /* Adding cognito auth token */
    options.headers.accesstoken = authtoken;
    return options;
  }


  /** ************************************
   *    GET
   ************************************ **/

  get<T>(url: string, options : any = {}) : Observable<T> {
    let obs = from(Auth.currentSession())
      .map(currentSession => {
        const authtoken = currentSession.getAccessToken().getJwtToken();
        const newOptions : object = this.addToken(options, authtoken);
    
        const client  :  HttpClient = this.http;
        const result  :  Observable<T> = client.get<T>(url, newOptions);

        return result;
      });
    return obs.switch();
  }

  /** ************************************
   *    POST
   ************************************ **/

  post<T>(url: string, body: object, options : object) : Observable<T> {
    let obs = from(Auth.currentSession())
      .map(currentSession => {
        console.log('http_auth: post: current session:', currentSession);
        console.log('http_auth: post: token:', currentSession.getAccessToken());
        
        const authtoken = currentSession.getAccessToken().getJwtToken();
        const newOptions : object = this.addToken(options, authtoken);
    
        const client  :  HttpClient = this.http;
        const result  :  Observable<T> = client.post<T>(url, body, newOptions);

        return result;
      });
    return obs.switch();
  }

  /** ************************************
   *    PUT
   ************************************ **/

  put<T>(url: string, body: object, options : object) : Observable<T> {
    let obs = from(Auth.currentSession())
    .map(currentSession => {
      console.log('http_auth: put: current session:', currentSession);
      console.log('http_auth: put: token:', currentSession.getAccessToken());
      
      const authtoken = currentSession.getAccessToken().getJwtToken();
      const newOptions : object = this.addToken(options, authtoken);
  
      const client  :  HttpClient = this.http;
      const result  :  Observable<T> = client.put<T>(url, body, newOptions);

      return result;
    });
    return obs.switch();
  }

  /** ************************************
   *    DELETE
   ************************************ **/

  delete<T>(url: string, options : object) : Observable<T> {
    let obs = from(Auth.currentSession())
    .map(currentSession => {
      console.log('http_auth: delete: current session:', currentSession);
      console.log('http_auth: delete: token:', currentSession.getAccessToken());
      
      const authtoken = currentSession.getAccessToken().getJwtToken();
      const newOptions : object = this.addToken(options, authtoken);
  
      const client  :  HttpClient = this.http;
      const result  :  Observable<T> = client.delete<T>(url, newOptions);

      return result;
    });
    return obs.switch();
  }

}

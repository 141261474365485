import { Action } from '@ngrx/store';
import { FrameLabel } from 'src/app/models/frame_label';

export enum EFrameLabelsActions {
	LoadForFrame = '[FrameLabels] Load for frame',
  LoadForFrameSuccess = '[FrameLabels] Load for frame Success',
	LoadForFrames = '[FrameLabels] Load for frames',
  LoadForFramesSuccess = '[FrameLabels] Load for frames Success',
  Create = '[FrameLabels] Create',
	CreateSuccess = '[FrameLabels] Create Success',
	Delete = '[FrameLabels] Delete',
	DeleteSuccess = '[FrameLabels] Delete Success'
}

export class LoadLabelsForFrame implements Action {
	public readonly type = EFrameLabelsActions.LoadForFrame;
  constructor(public frameID: number) {}
}

export class LoadLabelsForFrameSuccess implements Action {
	public readonly type = EFrameLabelsActions.LoadForFrameSuccess;
	constructor ( public labels: FrameLabel[] ) {}
}

export class LoadLabelsForFrames implements Action {
	public readonly type = EFrameLabelsActions.LoadForFrames;
  constructor(public frameIDs: number[]) {}
}

export class CreateFrameLabel implements Action {
  public readonly type = EFrameLabelsActions.Create;
  constructor ( public label: FrameLabel ) {}
}

export class CreateFrameLabelSuccess implements Action {
	public readonly type = EFrameLabelsActions.CreateSuccess;
	constructor ( public label: FrameLabel ) {}
}

export class DeleteFrameLabel implements Action {
  public readonly type = EFrameLabelsActions.Delete;
  constructor ( public id: number ) {}
}

export class DeleteFrameLabelSuccess implements Action {
	public readonly type = EFrameLabelsActions.DeleteSuccess;
  constructor ( public id: number ) {}
}

export type FrameLabelsActions = 
	LoadLabelsForFrame | LoadLabelsForFrameSuccess | CreateFrameLabel | CreateFrameLabelSuccess | DeleteFrameLabel | DeleteFrameLabelSuccess;
import { AppState } from '../state/app.state';
import { createSelector } from '@ngrx/store';
import { Job } from 'src/app/models/job';
import * as _ from 'lodash';
import { Basket } from 'src/app/models/basket';
import { BasketsSelectors } from './baskets.selectors';

const getJobs = (state: AppState) => state.jobs;

export const selectJobs = createSelector(
  getJobs,
  (jobs : Record<number, Job>) => _.values(jobs)
)

export const selectJobsDict = createSelector(
  getJobs,
  (jobs : Record<number, Job>) => jobs
)

export const selectFullJobs = createSelector(
  BasketsSelectors.instance.selectBasketsWithFramesDict,
  selectJobs,
  (frameBaskets: Record<number, Basket>, jobs: Job[]) => jobs.map(job => ({
    ...job,
    frame_basket: frameBaskets[job.frame_basket_id]
  }))
)

export const selectFullJobsDict = createSelector(
  BasketsSelectors.instance.selectBasketsWithFramesDict,
  selectJobsDict,
  (frameBaskets: Record<number, Basket>, jobs: Record<number, Job>) =>
    _.mapValues(jobs, job => ({
      ...job,
      frame_basket: frameBaskets[job.frame_basket_id]
    })
  )
)
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';


import { Subscription, Observable } from 'rxjs/Rx';
import { AppState } from '../../store/state/app.state';
import { HttpAuthClient }       from 'src/app/services/http_auth';
import { Store, select } from '@ngrx/store';
import { environment }          from 'src/environments/environment';
import { selectConfiguration } from '../../store/selectors/configuration.selector';

import { DOCUMENT } from '@angular/common';
import * as _ from 'lodash';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { selectBackendConfiguration } from 'src/app/store/selectors/backend-configuration.selectors';
import { BackendConfiguration } from 'src/app/models/backend_configuration';

@Component({
  selector: 'debug-tab',
  templateUrl: './debug-tab.component.html',
  styleUrls: ['./debug-tab.component.scss']
})

export class DebugTabComponent implements OnInit {
  protected subscriptions : Subscription[] = [];

  private config$ = this.store.pipe(select(selectConfiguration));
  private backendConfig$ = this.store.pipe(select(selectBackendConfiguration));
  serverUrl: string;
  backendConfig: BackendConfiguration;

  version: string;  

  selectedTabNumber: number = 0;


  constructor(
    private httpAuthClient : HttpAuthClient,
    protected store : Store<AppState>,
    private configService : ConfigurationService
  ) { 
		this.subscriptions.push(
			this.config$
      .subscribe(config => {
        this.serverUrl = config.serverUrl
      }));
	}

  switchToTab(tabNumber: number) {
    this.selectedTabNumber = tabNumber;
  }

  ngOnInit(): void {
    this.version   = environment.version;
    this.backendConfig$
    .subscribe(data => {  console.log(data); this.backendConfig = data});

  }

  userData: string;

  
}

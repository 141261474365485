import { Action } from '@ngrx/store';
import { Configuration } from '../../models/configuration';

export enum EConfigurationActions {
	LoadConfiguration = '[Configuration] Load Configuration',
	LoadConfigurationSuccess = '[Configuration] Load Configuration Success'
}

export class LoadConfiguration implements Action {
	public readonly type = EConfigurationActions.LoadConfiguration;
}

export class LoadConfigurationSuccess implements Action {
	public readonly type = EConfigurationActions.LoadConfigurationSuccess;
	constructor ( public configuration: Configuration ) {}
}

export type ConfigurationActions = LoadConfiguration | LoadConfigurationSuccess;
import * as moment from "moment";
import { SortOrder } from "./sort_order";
import { VideoFrame, VideoFrameStatus } from "./video_frame";

export enum VideoFrameSortColumn {
  Id = 'id',
  CreationDate = 'creation_date',
  VideoId = 'video_id',
  VideoDate = 'video_date' 
}

export class VideoFrameFilters {
  sortColumn: VideoFrameSortColumn = VideoFrameSortColumn.VideoDate;
  sortOrder: SortOrder = SortOrder.Desc;
  searchQuery?: string = null
  statusFilter?: VideoFrameStatus = null;
  tagFilter?: string = null;
  creationDateFilter?: string = null;

  getSortColumnKeyValue = (frame: VideoFrame) => {
    switch (this.sortColumn) {
      case VideoFrameSortColumn.CreationDate:
        return moment(frame.creation_date).format('YYYY-MM-DD');
      case VideoFrameSortColumn.Id:
        return frame.id;
      case VideoFrameSortColumn.VideoDate:
        return moment(frame.video_date).format('YYYY-MM-DD');
      case VideoFrameSortColumn.VideoId:
        return frame.video_id;
      default:
        return null;
    }
  }
}
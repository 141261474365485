import { Action } from '@ngrx/store';
import { BackendConfiguration } from 'src/app/models/backend_configuration';

export enum EBackendConfigurationActions {
  Load = '[BackendConfiguration] Load',
  LoadSuccess = '[BackendConfiguration] LoadSuccess'
}

export class LoadBackendConfiguration implements Action {
  public readonly type = EBackendConfigurationActions.Load;
  constructor () {}
}

export class LoadBackendConfigurationSuccess implements Action {
  public readonly type = EBackendConfigurationActions.LoadSuccess;
  constructor (public config: BackendConfiguration) {}
}

export type BackendConfigurationActions = LoadBackendConfiguration | LoadBackendConfigurationSuccess;
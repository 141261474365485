import { Component, OnInit } from '@angular/core';

import { utilsConvert }      from 'src/app/utils/utils'

import Tag                   from 'src/app/models/tag';
import UploadedFile          from 'src/app/models/uploaded_file';
import { Actor, ActorTarget} from 'src/app/models/actor';
import { UploadChunk }     from 'src/app/models/upload_chunk';


import { UploadsService }    from 'src/app/services/uploads.service';
import { ActionsService }    from 'src/app/services/actions.service';

import * as _ from 'lodash';
import { TagService } from 'src/app/services/tag.service';

@Component({
  selector: 'debug-videos',
  templateUrl: './debug-videos.component.html',
  styleUrls: ['./debug-videos.component.scss']
})



export class DebugVideosComponent implements OnInit {

  uploadedFiles: UploadedFile[] = [];
  actors       : Actor[] = [];
  videoUsers   : string[] = ["Mal", "Jane", "Inara", "Kaylee"];

  selectedActor : number = 0;

  selectedUser  : string = "";

  showDeleted    : number  = 1; /* 0 - deleted , 1 - not deleted , 2 - both */
  showUploaded   : number  = 0; /* 0 - uploaded, 1 - not uploaded, 2 - both */

  videoReqLimit  : number = 50;
  videoReqOffset : number = 0;
  
  checkedFiles  : UploadedFile[] = [];

  constructor(
    private uploadsService    : UploadsService,
    private actionsService    : ActionsService,
    private tagService        : TagService
  ) { }


  actorSelected(event: any): void
  {
    console.log(event);
    this.selectedActor = event.target.selectedIndex - 1;
  }

  videoUserSelected(name : string, index: number) : void
  {
      this.selectedUser = name;
  }

  filterVideos() : void {
    this.refresh();
  }

  ngOnInit(): void {
    this.refresh();
  }

  onCheckChanged(file: UploadedFile, event: any) : void {
    if (event.target.checked) {
      this.checkedFiles.push(file);
    }
    else {
      _.remove(this.checkedFiles, (f) => f === file);
    }
  }

  onShowDeletedChanged(event : any) : void {
    this.showDeleted = event.target.selectedIndex;
    console.log("this.showDeleted", this.showDeleted );
  }

  onShowUploadedChanged(event : any) : void {
    this.showUploaded = event.target.selectedIndex;
    console.log("this.showUploaded", this.showUploaded );
  }  

  deletedSelected(event : any) : void {


  }
  
  uploadedSelected(event : any) : void {


  }


  async deleteCheckedFiles(){
    const error = await this.uploadsService.deleteUploads(this.checkedFiles);
    if (error) {
      console.error(error);
      alert(error.message);
    }
    this.refresh();
    this.checkedFiles = [];
  }

  async startActionCheckedFiles() {
    console.log(this.selectedActor);
    if (this.selectedActor < 0)
      return;

    let actor = this.actors[this.selectedActor];
    this.actionsService
    .startVideoActions(actor, this.checkedFiles)
    .subscribe((res) => null, (error) => {
      console.error(error);
      alert(error.message);
    })
    this.refresh();
    this.checkedFiles = [];
  }


  sort : any = {
    id          : 1,
    size        : 1,
    upload_date : 2,
    hash        : 1
  }

     onIdSort() : void
   {
     this.sort.id = (this.sort.id + 1) % 3;
   }

   onSizeSort() : void
   {
     this.sort.size = (this.sort.size + 1) % 3;
   }

   onHashSort() : void
   {
     this.sort.hash = (this.sort.hash + 1) % 3;
   }

   onUploadDateSort() : void
   {
     this.sort.upload_date = (this.sort.upload_date + 1) % 3;

   }

   onLimitChanged(event : any) {
      this.videoReqLimit = event.target.value;
   }
   onOffsetChanged(event : any)
   {
      this.videoReqOffset = event.target.value;
   }

   refresh() {

    let filters : any [] = [];
    if (name) {
      filters.push({name: 'user'    , value: this.selectedUser});
    }


    if (this.showDeleted === 0) {
      filters.push({name: 'deleted'  , value: 1});
    }
    if (this.showDeleted === 1) {
      filters.push({name: 'remaining', value: 1});
    }
    if (this.showUploaded === 0) {
      filters.push({name: 'uploaded' , value: 1});
    }
    if (this.showUploaded === 1) {
      filters.push({name: 'loading'  , value: 1});
    }


    // {name: 'deleted' , value: this.showDeleted}


    this.uploadsService.getUploadsEx(
      filters, 
      [ 
        {name: 'id'          , order: this.sort.id          }, 
        {name: 'size'        , order: this.sort.size        },
        {name: 'upload_date' , order: this.sort.upload_date },
        {name: 'hash'        , order: this.sort.hash        } 
      ],  this.videoReqLimit, this.videoReqOffset
    )
    .subscribe(data => {
      this.uploadedFiles = data;
      /*So far make it here, then it would go to db*/
      let idset: Set<number> = new Set<number>();

      for (let videoFile of this.uploadedFiles) {
        if (! videoFile.thumbnail_url) {
          if (videoFile.contentType.endsWith("zip")) {
              videoFile.thumbnail_url="assets/images/database.svg";
          } else {
              videoFile.thumbnail_url="assets/images/video-player.svg";
          }
        }
        // @ts-ignore
        videoFile.tags.forEach(tag => idset.add(tag));
      }

      let ids : number[] = []
      for (let entry of idset) {
        ids.push(entry);
      }


      this.tagService.getTagsByIds(ids).subscribe( tags => {
          /* Pack it to map */
          let tagsMap : Tag[] = [];
          tags.forEach(tag => tagsMap[tag.id] = tag);

          for (let videoFile of this.uploadedFiles) {
            for (let i = 0; i < videoFile.tags.length; i++) {
              // @ts-ignore
              videoFile.tags[i] = tagsMap[videoFile.tags[i]];
            }            
          }
      });

      console.log(data);
    });

    this.uploadsService.getUploadUsers()
    .subscribe(data => {
      this.videoUsers = data;
      console.log(data);
    });

    this.actionsService.getActors(ActorTarget.Video, true)
    .subscribe(data => {
      this.actors = data;
      if (data.length > 0) {
        this.selectedActor = -1;
      }
      console.log(data);
    });

  }

  thumbnailChange(event: any, state:number, video_info:any ) : void
  {
    /*console.log(state.toString + "  " + event);*/
    if (state == 1) {
      event.target.src = (video_info.thumbnail_video_url != null) ? video_info.thumbnail_video_url : video_info.thumbnail_url     ;
    } 
    if (state == 0) {
      event.target.src = (video_info.thumbnail_url       != null) ? video_info.thumbnail_url       : video_info.thumbnail_video_url;
    } 
    
  }


  /* Chunks */
  chunks: UploadChunk[] = [];
  chunkVideo : number = -1;

  updateChunks(video_id : number) : void {
    this.chunkVideo = video_id;
 
    console.log("updateChunks(): called " + this.chunkVideo);
    
    this.uploadsService.getChunks(this.chunkVideo)
    .subscribe(data => {this.chunks = data});
  }

  /*onVideoChanged(event:any) : void {
    this.chunkVideo = event.target.value;
  }*/

  getColor(status:number) : string {
    if  (status == 0) return "#FF0000";
    if  (status == 1) return "#FFFF00";
    if  (status == 2) return "#00FF00";
    return "#00FF00";
  }

  getTexual(status:number) : string {
    if  (status == 0) return "inited";
    if  (status == 1) return "in upload";
    if  (status == 2) return "uploaded";
    return "#00FF00";
  }


  /* Utils */

  getSimpleSize    = utilsConvert.getSimpleSize;
  getSimpleDate    = utilsConvert.getSimpleDate;
  getSimpleTime    = utilsConvert.getSimpleTime;
  getColorCode     = utilsConvert.getColorCode;
  getPrintableHash = utilsConvert.getPrintableHash;
 



}

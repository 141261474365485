import { Auth } from 'aws-amplify';


export let utilsAuth = {    
    getDroppedPrivelegeArray: () : string[] => {
        let dropGroups = { dropPrivledege : false };
        let stored = localStorage.getItem("su");
        if (stored) {
          dropGroups = JSON.parse(stored);
        }
                  
        if (dropGroups.dropPrivledege) {
            let groupList : string[] = [];
            if (dropGroups['uploader'])  groupList.push("uploader");
            if (dropGroups['annotator']) groupList.push("annotator");
            if (dropGroups['manager'])   groupList.push("manager");
            return groupList;
        }
        return null;
    },

    shouldDropPrivelege: () : boolean => {
        let dropGroups = { dropPrivledege : false };
        let stored = localStorage.getItem("su");
        if (stored) {
          dropGroups = JSON.parse(stored);
        }
        return dropGroups.dropPrivledege;
    },

    getGroups: async () : Promise<string[]> => {
        const currentSession = await Auth.currentSession();
        let accessTokenPayload =  currentSession.getAccessToken().decodePayload(); 
        let groups : string[] = accessTokenPayload['cognito:groups'];

        let superuser: boolean = false;
        if (groups.includes('superuser')) {
          superuser = true;
        }

        if (superuser) {
            let dropTo = utilsAuth.getDroppedPrivelegeArray();
            if (dropTo) {
                return dropTo;
            }
        }
        return groups;
    },

    /* Experimental - register myself to DB */
    checkOrRegister: async(): Promise<void> => {
    Auth.currentAuthenticatedUser()
    .then(currentUser => 
      {
        console.log(currentUser);
        let nickname : string = currentUser.username;
        let email    : string = currentUser.attributes.email;

        Auth.currentSession()
        .then(currentSession => 
            {
              console.log(currentSession);
              let accessTokenPayload =  currentSession.getAccessToken().decodePayload(); 
              let groups : string[] = accessTokenPayload['cognito:groups'];
              this.userService.addUser(nickname, email, groups);
            });
      });
    }

};
  
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { switchMap, withLatestFrom, concatMap} from 'rxjs/operators';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { JobService } from 'src/app/services/jobs.service';
import { LoadJobs, EJobsActions, LoadJobsSuccess, CreateJob, CreateJobSuccess, UpdateJob, UpdateJobSuccess } from '../actions/jobs.actions';
import { Job, JobStatus } from 'src/app/models/job';
import { Store, Action } from '@ngrx/store';
import { AppState } from '../state/app.state';
import * as _ from 'lodash';
import { VideoFrameStatus, VideoFrame } from 'src/app/models/video_frame';
import { ShowNotification } from '../actions/notifications.actions';
import { NotificationType } from 'src/app/models/notification';
import { BasketsSelectors } from '../selectors/baskets.selectors';
import { Update, ECollectionItemType } from '../actions/collection.actions';

@Injectable()
export class JobsEffects {
	constructor(
		private store: Store<AppState>,
		private jobService: JobService,
    private actions$: Actions
  ) {}

	@Effect()
	getJobs$ = this.actions$.pipe(
		ofType<LoadJobs>(EJobsActions.LoadJobs),
		switchMap(() => this.jobService.getJobs()),
		switchMap((jobs) => {
			return [
				new LoadJobsSuccess(jobs)
			];
		})
	)
	
	@Effect()
	createJob$ = this.actions$.pipe(
		ofType<CreateJob>(EJobsActions.CreateJob),
		switchMap((action) => this.jobService.createJob(action.job)),
		switchMap((job) => {
			if (job) {
				return [ 
					new CreateJobSuccess(job),
					new ShowNotification("Job created successfully", NotificationType.Success)
				];
			}
			else {
				return [];
			}
		})
	)

	@Effect()
	updateJob$ = this.actions$.pipe(
		ofType<UpdateJob>(EJobsActions.UpdateJob),
		switchMap((action) => this.jobService.updateJob(action.job)),
		concatMap((job: Job) => of(job).pipe(
			withLatestFrom(this.store.select(BasketsSelectors.instance.selectBasketsWithFramesDict))
		)),
		switchMap(([job, baskets]) => {
			const frames = baskets[job.frame_basket_id].videoFrames;
			console.log('job frames,', frames);
			const frameUpdateEvents : Action[] =
				job.status === JobStatus.Completed
				? frames
					.filter(f => f.status === VideoFrameStatus.InProgress || f.status === VideoFrameStatus.None)
					.map(f => new Update<VideoFrame>(
						{...f, status: VideoFrameStatus.Classified},
						ECollectionItemType.VideoFrame))
				: []
			return frameUpdateEvents.concat(new UpdateJobSuccess(job));
		})
	)
}
import { AppState } from "../state/app.state";
import { createSelector } from '@ngrx/store';
import { Basket, BasketType } from 'src/app/models/basket';
import * as _ from 'lodash';
import { VideoFrame } from 'src/app/models/video_frame';
import { CollectionSelectors, FramesSelectors, OntologySelectors } from './collection.selectors';
import ObjectOntologyClass from 'src/app/models/object_ontology_class';

const getBaskets = (state: AppState) => state.baskets;

export class BasketsSelectors extends CollectionSelectors<Basket> {
  private constructor() {
    super(getBaskets);
  }

  public static instance : BasketsSelectors = new BasketsSelectors();

  selectVideoFrameBaskets = createSelector(
    getBaskets,
    baskets => {
      console.log('selectVideoFrameBaskets');
      return _.pickBy(baskets, (v : Basket, k) => v.type === BasketType.VideoFrames);
    }
  );

  selectVideoFrameBasketsList = createSelector(
    this.selectVideoFrameBaskets,
    baskets => _.values(baskets)
  );

  selectBasketsWithFramesDict = createSelector(
    FramesSelectors.selectDict,
    this.selectVideoFrameBaskets,
    (frames: Record<number, VideoFrame>, baskets : Record<number, Basket>) => {
      console.log("selectBasketsWithFramesDict");
      return _.mapValues(baskets, (basket) => ({
        ...basket,
        videoFrames: basket.videoFrameIds ? basket.videoFrameIds.map(frameId => frames[frameId]) : []
      }))
    }
  )
} 
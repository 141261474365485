import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { HttpAuthClient } from './http_auth';
import ObjectOntologyClass from '../models/object_ontology_class';
import { HttpServiceBase } from './http_service_base';
import { AppState } from '../store/state/app.state';
import { Auth } from 'aws-amplify';

@Injectable({
  providedIn: 'root'
})
export class OntologyService extends HttpServiceBase {
  constructor(
    private httpAuth: HttpAuthClient,
    protected store : Store<AppState>
  ) {
    super(store);
  }

  getOntology() : Observable<ObjectOntologyClass[]> {
    return Observable
      .fromPromise(Auth.currentAuthenticatedUser())
      .switchMap((currentUser) => {
      return this.httpAuth.get<ObjectOntologyClass[]>(`${this.serverUrl}/api/ontology/`);
    })
  }

/*
  getUploadsEx() : Observable<ObjectOntologyClass[]> {
    // @ts-ignore
    let input : ObjectOntologyClass[] = this.httpAuth.get<ObjectOntologyClass[]>(`${this.serverUrl}/api/ontology/`);



    //let output : Observable<ObjectOntologyClass[]>;
    return input;
  }
*/  

}

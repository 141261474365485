import { Basket } from './basket';
import ObjectOntologyClass from './object_ontology_class';

export enum JobStatus {
  New = 0,
  InProcess = 1,
  Completed = 2,
  Returned = 3,
  Closed = 4,
  RequiresInspection = 5
}

export class Job {
  id?            : number; 
  description    : string; 
  status         : JobStatus;  
  frame_basket_id: number;
  frame_basket?  : Basket;
  assigned_to?   : string;
  classes?       : ObjectOntologyClass[]; // classes
};


import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import FileToUpload from 'src/app/models/file_to_upload';
import { getHash } from 'src/app/models/file_to_upload';
import { utilsConvert }    from 'src/app/utils/utils'

import UploadedFile from 'src/app/models/uploaded_file';
import { UploadsService } from 'src/app/services/uploads.service';
import UploadError from 'src/app/models/upload_error';
import { AgGridAngular } from 'ag-grid-angular';

import * as _  from 'lodash';
import { TagsCellRendererComponent } from '../tags-cell-renderer/tags-cell-renderer.component';
import { TagService } from 'src/app/services/tag.service';
import Tag from 'src/app/models/tag';
import { Auth } from 'aws-amplify';
import { AppState } from 'src/app/store/state/app.state';
import { Store } from '@ngrx/store';
import { Load, ECollectionItemType } from 'src/app/store/actions/collection.actions';

@Component({
  selector: 'upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.scss']
})
export class UploadDialogComponent implements OnInit, AfterViewInit, OnChanges {
  @Input('files') files: FileToUpload[];
  @ViewChild('agGrid', {static: false}) agGrid: AgGridAngular;

  availableTags : Tag[] = [];
  commonTags: Tag[] = [];
  commonComments : string = null;

  gridOptions = {
    suppressColumnVirtualisation: true,
    suppressRowTransform: true
  }
  columnDefs = [
    {
      headerName: '',
      field: 'file.name',
      width: 56,
      cellRenderer: (params) => '<span uk-icon="icon: close" class="remove"></span>',
      onCellClicked: (e) => { this.deleteUpload(e.data); }
    },
    {headerName: 'File name', field: 'file.name', resizable: true },
    {
      headerName: 'Size',
      field: 'file.size',
      resizable: true,
      valueFormatter: (params) => Math.round(params.value /  2**20) + 'Mb'
    },
    {
      headerName: 'Status',
      field: 'progress',
      valueFormatter: (params) =>{
        if (params.data.error) {
          return 'Error';
        }
        else if (params.data.progress == 0) {
          return 'New';
        }
        else if (params.data.progress >= 100) {
          return 'Uploaded';
        }
        else {
          return 'Uploading';
        }
      }
    },
    {
      headerName: 'Progress',
      field: 'progress',
      cellRenderer: (params) => `<span data-uk-tooltip title="${params.value} %"><progress class="uk-progress" value="${params.value}" max="100"></progress></span>`
    },
    {
      headerName: 'Date',
      field: 'date',
      valueFormatter: (params) => params.value.toLocaleDateString() + " " + params.value.toLocaleTimeString()
    },
    {
      headerName: 'Comments',
      field: 'comment',
      editable: true,
      resizable: true
    },
    {
      headerName: 'Tags',
      field: 'file',
      width: 300,
      cellRendererFramework: TagsCellRendererComponent,
      resizable: true
    },
    {
      headerName: 'Status',
      field: 'hash',
      width: 300,
      cellRenderer: (params) => 
        {
          let hash_result: string = '<span>Computing fingerprint...</span>';
          if (params.data.hash) {
              hash_result = '<span>' + this.getPrintableHash(params.value) + '</span>';
          }
          let state : string = "<span>Computing fingerprint...</span>";

          if (params.data.video && params.data.video.length > 0) {
            let videos = params.data.video;

            state = '<span style="background-color: ##f7ff17;" data-uk-tooltip title="' + "Hash:" + hash_result + '">Video has ('+ videos.length + ') prior upload attemps</div>'
            /* There could me multiple entries so far */
            for (let i = 0; i < videos.length; i++ )
            {
              let video = videos[i];
              if (video.uploaded) {
                state = '<span style="background-color: #74ff17;" data-uk-tooltip title="' + "Hash:<br>" + hash_result + "<br>Uploaded:" + video.upload_date + '">Video Already uploaded('+ videos.length + ')</span>';
                break;
              } 
            }
          } else {
            state = '<span data-uk-tooltip title="Hash:<br>' + hash_result + '" >New</span>';
          }
          return state;
        },
      onCellClicked: (e) => { }
    }
  ];

  dropdownSettings = {
    singleSelection: false,
    text: "Select tags",
    enableSearchFilter: true,
    addNewItemOnFilter: true,
    labelKey: "name",
    searchBy: ['name']
  };

  constructor(
    private store: Store<AppState>,
    private uploadsService: UploadsService,
    private tagService: TagService
    ) { }

  ngOnInit() {
    this.tagService.getTags('', 100).subscribe(tags => {
      this.availableTags = tags;
    });
  }

  ngAfterViewInit(): void {
  }

  async ngOnChanges(changes:  SimpleChanges) {
    if (changes.files == undefined)
      return;

    if (changes.files.currentValue == undefined)
      return;

    let files = changes.files.currentValue;

    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      getHash(file).then(
        async hash => {
          this.uploadsService.getUploadsEx(
            [
              { name : "hash", value : file.hash}
            ], [], 0, 0
          ).subscribe (
            data => {
              file['video'] = data;
              this.agGrid.api.refreshCells(
                {
                  /*rowNodes: [this.getRowNodeId(file)],*/
                  columns:  ["hash"],
                  force: true
                }
              );  
            } 
          )          
        }
      );
    }
  }

  async uploadFiles() {
    let uploadResults : (UploadedFile | UploadError)[] = null;
    try {
      uploadResults = await this.uploadsService.uploadFiles(
        this.files.map(f => {
          let fileWithMeta = new FileToUpload();
          
          fileWithMeta.file = f.file;
          fileWithMeta.date = f.date;
          fileWithMeta.hash = f.hash;

          fileWithMeta.tags     = [...this.commonTags, ...f.tags];
          fileWithMeta.comment  = [this.commonComments, f.comment].filter(s => s && s!="").join("; ");          
          
          return [ fileWithMeta, (progress) => { this.setProgress(f, progress); } ];
        })
      );
      
      // if one of files has a new tag, we need to invalidate tag cache
      if (this.files.findIndex(f => f.tags.findIndex(t => !t.id) >= 0) >= 0) {
        this.tagService.invalidateCache();
      }
    }
    catch (e) {
      alert(`Failed to upload. ${e}`);
      return;
    }
    finally {
      this.store.dispatch(new Load<UploadedFile>(ECollectionItemType.Video));
    }

    for (let uploadResultO of uploadResults) {
      // @ts-ignore
      if (uploadResultO.error) {
        console.error("Failed to upload file: ", uploadResultO);
        let uploadResult = uploadResultO as UploadError;
        const originalFile = this.files.find(file => file.file.name == uploadResult.fileName);
        originalFile.error = uploadResult.error;
      }
      else {
        let uploadResult = uploadResultO as UploadedFile;
        const originalFile = this.files.find(file => file.file.name == uploadResult.fileName);
        if (originalFile) {
          this.setProgress(originalFile, 100);
        }
      }
    }
  }

  getFileSizeInMb(file: File) : number {
    return Math.round(file.size /  2**20);
  }

  updateComment(file: FileToUpload, event: any) : void {
    file.comment = event.target.value;
  }

  setProgress(file : FileToUpload, progress: number): void {
    const rowNode = this.agGrid.api.getRowNode(file.file.name);
    const newProgress = Math.max(file.progress, progress);
    if (rowNode) {
      rowNode.setDataValue('progress', newProgress);
    }
    file.progress = newProgress;
  }

  getRowNodeId(data) {
    return data.file.name;
  }

  isUploadInProgress() : boolean {
    return this.files && this.files.findIndex((file) => file.progress < 100 && file.progress > 0) >= 0;
  }

  isAllFilesLoaded(): boolean {
    return !this.files || this.files.findIndex((file) => file.progress < 100) < 0;
  }

  deleteUpload(file: FileToUpload) : void  {
    _.remove(this.files, (el) => el.file == file.file);
    if (this.files.length > 0) {
      this.agGrid.api.applyTransaction({ remove: [ file ] });
    }
    else {
      const closeButtons = document.getElementsByClassName('uk-modal-close');
      //@ts-ignore
      closeButtons[0].click();
    }
  }

  removeCompleted() {
    const completedFiles = this.files.filter(file => file.progress >= 100);
    for (let file of completedFiles) {
      this.deleteUpload(file);
    }
  }

  async onAddItem(value: string): Promise<void> {
    const newTag: Tag = {id: null, name: value, color: '0', user_id: null, popualrity: 0};
    this.commonTags.push(newTag);

    const currentUser = await Auth.currentAuthenticatedUser();
    newTag.user_id = currentUser.username;
  }

  onTagsChanged() {
  }

  getPrintableHash = utilsConvert.getPrintableHash;
}

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { utilsConvert }    from 'src/app/utils/utils'
import UIkit from 'uikit';

import ObjectOntologyClass from 'src/app/models/object_ontology_class';

import { OntologyService }      from 'src/app/services/ontology.service';
import { environment }          from 'src/environments/environment';


import { DOCUMENT } from '@angular/common';
import * as _ from 'lodash';

@Component({
  selector: 'debug-ontologies',
  templateUrl: './debug-ontologies.component.html',
  styleUrls: ['./debug-ontologies.component.scss']
})

export class DebugOntologiesComponent implements OnInit {
  serverUrl: string;

  ontologyClasses: ObjectOntologyClass[] = [];
  constructor(
    private ontologyService   : OntologyService,
  ) { }


  ngOnInit(): void {
    this.serverUrl = environment.serverUrl;

    this.refresh();
  }

  getColorCode = utilsConvert.getColorCode;

  refresh() {
    this.ontologyService.getOntology()
    .subscribe(data => {
      this.ontologyClasses = data;
      console.log(data);
    });
  }
}

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import { HttpAuthClient } from './http_auth';
import { HttpServiceBase } from './http_service_base';
import { AppState } from '../store/state/app.state';
import NNConfiguration from '../models/inference';

@Injectable({
	providedIn: 'root'
})

export class InferenceService extends HttpServiceBase {
	constructor(
		private httpAuth: HttpAuthClient,
		protected store : Store<AppState>
	) {
		super(store);
	}

	getNNConfigurations() : Observable<NNConfiguration[]> {
		return this.httpAuth.get<NNConfiguration[]>(`${this.serverUrl}/api/nnconfigurations`);
	} 

}
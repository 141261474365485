import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import Amplify from 'aws-amplify';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Basket } from 'src/app/models/basket';
import { FrameLabel } from 'src/app/models/frame_label';
import { Job } from 'src/app/models/job';
import ObjectOntologyClass from 'src/app/models/object_ontology_class';
import { VideoFrame } from 'src/app/models/video_frame';
import { ChangeFrameIndex, ChangeJob } from 'src/app/store/actions/annotator-component.actions';
import { ECollectionItemType, Load } from 'src/app/store/actions/collection.actions';
import { LoadConfiguration } from 'src/app/store/actions/configuration.actions';
import { CreateFrameLabel, DeleteFrameLabel, LoadLabelsForFrames } from 'src/app/store/actions/frame-labels.actions';
import { LoadJobs } from 'src/app/store/actions/jobs.actions';
import { selectCurrentFrameIndex, selectJob } from 'src/app/store/selectors/annotator-component.selectors';
import { OntologySelectors } from 'src/app/store/selectors/collection.selectors';
import { selectConfiguration } from 'src/app/store/selectors/configuration.selector';
import { selectLabelsForFrame } from 'src/app/store/selectors/frame-labels.selectors';
import { AppState } from 'src/app/store/state/app.state';

interface CrowdAwsBondingBox {
  width: number,
  height: number,
  left: number,
  top: number,
  label: string
}

@Component({
  selector: 'crowd-aws-annotator',
  templateUrl: './crowd-aws-annotator.component.html',
  styleUrls: ['./crowd-aws-annotator.component.scss']
})
export class CrowdAwsAnnotatorComponent implements OnInit, OnDestroy, AfterViewInit {
  private subscriptions: Subscription[] = [];
  private hideWrongImageErrorMessageInterval = null;

  currentFrameIndex: number = 0;
  currentFrame: VideoFrame = null;
  currentFrameUrl: string = null;
  currentFrameBoundingBoxes: CrowdAwsBondingBox[] = [];
  currentFrameLabels: FrameLabel[] = [];
  job: Job;
  allClasses: Record<number,ObjectOntologyClass> = {};
  allClassNames: string[] = [];
  isFirstFrameInformationLoaded: boolean = false;
  showLockScreen: boolean = true;
  awsSettingsLoaded : boolean = false;

  private config$ = this.store.pipe(select(selectConfiguration));
  private job$ : Observable<Job> = this.store.pipe(select(selectJob));
  private currentFrameIndex$ : Observable<number> =
    this.store.pipe(select(selectCurrentFrameIndex));
  private currentFrame$: Observable<VideoFrame> =
    combineLatest([this.currentFrameIndex$, this.job$]).pipe(
      map(([frameIdx, job]) => 
        job && job.frame_basket && job.frame_basket.videoFrames
        ? job.frame_basket.videoFrames[frameIdx]
        : null
      )
    );
  private currentFrameLabels$ : Observable<FrameLabel[]> =
    this.currentFrame$.pipe(
      switchMap(frame => frame ? this.store.pipe(select(selectLabelsForFrame, {frameID: frame.id})) : [])
    );
  private allClasses$: Observable<Record<number, ObjectOntologyClass>> = this.store.pipe(select(OntologySelectors.selectDict));

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.store.dispatch(new LoadConfiguration());
    this.store.dispatch(new ChangeJob(this.route.snapshot.params['jobId']));
    this.store.dispatch(new ChangeFrameIndex(0));

    this.subscriptions.push(this.config$.subscribe(async c => {
      console.log('new config:', c);

      this.store.dispatch(new Load<ObjectOntologyClass>(ECollectionItemType.Ontology));
      this.store.dispatch(new LoadJobs());
      this.store.dispatch(new Load<Basket>(ECollectionItemType.Basket));
      this.store.dispatch(new Load<VideoFrame>(ECollectionItemType.VideoFrame));

      if (!!c.aws_cognito_region) {
        Amplify.configure(c);
        this.awsSettingsLoaded = true;
      }
    }));

    this.subscriptions.push(
      this.job$.subscribe((job: Job) => {
        console.log('annotator: changed job:', job);
        this.job = job;

        if (job && job.frame_basket && job.frame_basket.videoFrameIds && job.frame_basket.videoFrameIds.length > 0) {
          this.store.dispatch(new LoadLabelsForFrames(job.frame_basket.videoFrameIds));
        }
      })
    );
    this.subscriptions.push(
      this.currentFrameIndex$.subscribe((index: number) => {
        console.log('annotator: changed frame index:', index);
        this.currentFrameIndex = index;
      })
    );
    this.subscriptions.push(
      this.currentFrame$.subscribe(frame => {
        if (!frame) {
          return;
        }

        console.log('annotator: changed frame:', frame, frame.url);
        this.currentFrame = frame;
        this.currentFrameUrl = frame.url;
        const bounder = document.getElementsByTagName('crowd-bounding-box')[0];
        if (bounder) {
          bounder.setAttribute("src", this.currentFrameUrl);
        }
      })
    );
    this.subscriptions.push(
      this.currentFrameLabels$.subscribe(labels => {
        this.currentFrameLabels = labels;
        this.currentFrameBoundingBoxes = labels.map(label => {
          let l = (label.object_class_id in this.allClasses) ? this.getClassFullName(this.allClasses[label.object_class_id]) : null;
          if (l == null) {
            l = "Unknown";
            console.log('annotator: unknown label:', label.id, this.allClasses[label.object_class_id]);
          }
          return {
            width: label.p2.x - label.p1.x,
            height: label.p2.y - label.p1.y,
            left: label.p1.x,
            top: label.p1.y,
            label: l
          };
        });
        console.log('annotator: frame labels:', this.currentFrameBoundingBoxes);

        this.isFirstFrameInformationLoaded = true;
        this.updateBoundingBoxesWithWait();
      })
    );

    this.subscriptions.push(
      this.allClasses$.subscribe((classes : Record<number, ObjectOntologyClass>) => {
        if (!classes) {
          return;
        }

        this.allClasses = classes;
        this.allClassNames = Object.values(classes).map(c => this.getClassFullName(c));
        this.allClassNames.push('Unknown');
      })
    );
  }

  ngAfterViewInit(): void {
    if (this.hideWrongImageErrorMessageInterval) {
      clearInterval(this.hideWrongImageErrorMessageInterval);
      this.hideWrongImageErrorMessageInterval = null;
    }
    this.hideWrongImageErrorMessageInterval = setInterval(
      this.hideWrongImageErrorMessage,
      300);
  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    clearInterval(this.hideWrongImageErrorMessageInterval);
    this.hideWrongImageErrorMessageInterval = null;
  }

  updateBoundingBoxes(bounder: Element) {
    bounder.setAttribute("initial-value", JSON.stringify(this.currentFrameBoundingBoxes));
    setTimeout(() => {
      bounder.setAttribute("src", this.currentFrameUrl);

      const crowdForm = document.getElementsByTagName("crowd-form")[0];
      if (crowdForm) {
        //@ts-ignore
        crowdForm.onsubmit = _.bind(this.onSubmit, this);
      } 

      setTimeout(() => {
        this.showLockScreen = false;
      }, 1000);
    }, 5000);
    // console.log("annotator: crowd-bounding-box:", bounder);
  }

  updateBoundingBoxesWithWait() {
    const bounder = document.getElementsByTagName('crowd-bounding-box')[0];
    if (bounder) {
      this.updateBoundingBoxes(bounder);
    }
    else {
      let executed = false;
      const iv = setInterval(() => {
        if (executed) {
          return;
        }

        const bounder2 = document.getElementsByTagName('crowd-bounding-box')[0];
        if (bounder2) {
          clearInterval(iv);
          executed = true;
          this.updateBoundingBoxes(bounder2);
        }
      }, 100);
    }
  }

  getClassFullName(c: ObjectOntologyClass) : string {
    return (c.path && c.path.length > 0) ? c.path.join('/') : c.name;
  }

  hideWrongImageErrorMessage() {
    const crowdForm = document.getElementsByTagName("crowd-form")[0];

    if (!crowdForm || !crowdForm.shadowRoot) {
      return;
    }

    crowdForm.shadowRoot.getElementById("errors-box").style.display = "none";
    crowdForm.shadowRoot.getElementById("answers-box").style.display = "none";

    const error =
      crowdForm
      .querySelector("crowd-bounding-box")
      .shadowRoot
      .querySelector('.awsui-flash-type-error');
      
    if (error) {
      //@ts-ignore
      error.style.display = 'none';
    }
  }

  backward() {
    if (this.currentFrameIndex > 0) {
      this.store.dispatch(new ChangeFrameIndex(this.currentFrameIndex - 1));
    }
  }

  forward() {
    if (this.currentFrameIndex < this.job.frame_basket.videoFrames.length -1) {
      this.store.dispatch(new ChangeFrameIndex(this.currentFrameIndex + 1));
    }
  }

  getOntologyClassByStringPath(path: string): ObjectOntologyClass {
    let splittedPath = path.split("/");
    console.log("annotator: splittedPath", splittedPath);
    let foundClass = Object.values(this.allClasses).find((oclass) => {
      if (oclass.path) {
        console.log("annotator: oclass:", oclass.name, oclass.path.length, splittedPath.length);
      }
      else {
        console.log("annotator: oclass:", oclass.name, "no path", oclass);
      }
      return (oclass.path && splittedPath.length == oclass.path.length &&
      oclass.path.findIndex((pathEl, idx) => {
        console.log("annotator: oclass el:", oclass.name, pathEl, splittedPath[idx], pathEl == splittedPath[idx]);
        return pathEl != splittedPath[idx];
      }) < 0) || (!oclass.path && splittedPath.length == 1 && oclass.name == splittedPath[0])
    });
      console.log("annotator: foundClass", foundClass);
    return foundClass;
  }

  saveNewLabel(boundingBox: CrowdAwsBondingBox) {
    let selectedClass = this.getOntologyClassByStringPath(boundingBox.label);
    if(!selectedClass) {
      return;
    }
    /* Login would be filled later */
    let label: FrameLabel  = {
      frame : this.currentFrame.id,
      object_class_id: selectedClass.id,
      user : "---",
      p1 : {
        x: boundingBox.left,
        y: boundingBox.top
      },
      p2 : {
        x: boundingBox.left + boundingBox.width,
        y: boundingBox.top + boundingBox.height
      },
      color: (256*256*125 + 256 * 200 + 213).toString(),
      enabled: true
    }

    // const frameLabels$ = this.store.pipe(select(selectLabelsForFrame, { frameID: this.currentFrame.id }));
    // let uss = null;
    // let executed = false;
    // uss = frameLabels$.subscribe(labels => {
    //   if (uss) {
    //     uss.unsubscribe();
    //   }
    //   if (!executed) {
    //     executed = true;
    //     console.log('countur saved');
    //     if(this.currentFrame.status === VideoFrameStatus.None || this.currentFrame.status === VideoFrameStatus.Classified) {
    //       this.updateFrameInProgress();
    //     }
    //   }
    // })
    this.store.dispatch(new CreateFrameLabel(label));
  }

  deleteLabel(boundingBox: CrowdAwsBondingBox) {
    let foundForDelete = this.currentFrameLabels.find((frameLabel) =>
        frameLabel.p1.y == boundingBox.top && frameLabel.p1.x == boundingBox.left &&
        Math.abs(Math.abs(frameLabel.p2.y - frameLabel.p1.y) - Math.abs(boundingBox.height)) <= 2 &&
        Math.abs(Math.abs(frameLabel.p2.x - frameLabel.p1.x) - Math.abs(boundingBox.width)) <= 2 &&
        this.allClasses[frameLabel.object_class_id] &&
        boundingBox.label.endsWith(this.allClasses[frameLabel.object_class_id].name));
    console.log("annotator: foundForDelete: ", foundForDelete);
    if (foundForDelete) {
      this.store.dispatch(new DeleteFrameLabel(foundForDelete.id));
    }
  }

  onSubmit() {
    //@ts-ignore
    const boundingBoxes : CrowdAwsBondingBox[] = document.querySelector('crowd-bounding-box').value.boundingBoxes || document.querySelector('crowd-bounding-box')._submittableValue.boundingBoxes;
    console.log("annotator: bounding boxes:", boundingBoxes);
    console.log("annotator: currentFrameLabels", this.currentFrameBoundingBoxes);

    let newBoundingBoxes = boundingBoxes.filter((boundingBox) =>
      this.currentFrameBoundingBoxes.find((frameLabel) =>
        frameLabel.top == boundingBox.top && frameLabel.left == boundingBox.left &&
        frameLabel.height == boundingBox.height && frameLabel.width == boundingBox.width &&
        frameLabel.label == boundingBox.label) == null);
    console.log("annotator: newBoundingBoxes",newBoundingBoxes);
    for(let boundingBox of newBoundingBoxes) {
      this.saveNewLabel(boundingBox);
    }

    let deletedBoundingBoxes = this.currentFrameBoundingBoxes.filter((frameLabel) =>
      boundingBoxes.find((boundingBox) =>
        frameLabel.top == boundingBox.top && frameLabel.left == boundingBox.left &&
        frameLabel.height == boundingBox.height && frameLabel.width == boundingBox.width &&
        frameLabel.label == boundingBox.label) == null);
    console.log("annotator: deletedBoundingBoxes", deletedBoundingBoxes);
    for(let boundingBox of deletedBoundingBoxes) {
      this.deleteLabel(boundingBox);
    }
  }
}

import { OnDestroy } from '@angular/core';
import { Subscription, Observable } from 'rxjs/Rx';
import { of } from 'rxjs';

import { Store, select } from '@ngrx/store';
import { AppState } from '../store/state/app.state';
import { selectConfiguration } from '../store/selectors/configuration.selector';

export class HttpServiceBase implements OnDestroy {
	protected subscriptions : Subscription[] = [];
	
	private config$ = this.store.pipe(select(selectConfiguration));
	protected serverUrl: string;

	constructor(
		protected store : Store<AppState>
	) {
		this.subscriptions.push(
			this.config$
      .subscribe(config => {
        this.serverUrl = config.serverUrl
      }));
	}

	ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
	}
	
	protected doRequest<T>(
		requestFn : ((url: string, params: any) => Observable<T>) | ((url: string, body: any, params: any) => Observable<T>), 
		url : string,
		options: any = {},
		body: object = null
	) : Observable<T> {
		if (this.serverUrl) {
			return requestFn(`${this.serverUrl}${url}`, options, body);
		}
		else {
				return this.config$
				.filter(config => !!config.serverUrl)
				.switchMap(config => {
					console.log('getActors: fot config', config, `${config.serverUrl}${url}`);
					return config.serverUrl != null
						? requestFn(`${config.serverUrl}${url}`, options, body)
						: of(null)
				})
		}
	}
}
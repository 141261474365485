import { Component, OnInit } from '@angular/core';
import ObjectOntologyClass from 'src/app/models/object_ontology_class';

@Component({
  selector: 'ontology-tab',
  templateUrl: './ontology-tab.component.html',
  styleUrls: ['./ontology-tab.component.scss']
})
export class OntologyTabComponent implements OnInit {
  selectedClass : ObjectOntologyClass = null;

  constructor() { }

  ngOnInit() {
  }

  onSelectedClassChanged(clickedClass : ObjectOntologyClass) {
    this.selectedClass = clickedClass;
    console.log("onSelectedClassChanged ", clickedClass);
  }

}

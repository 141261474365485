import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { VideoFrame }      from 'src/app/models/video_frame';
import UploadedFile        from 'src/app/models/uploaded_file';
import { FrameLabel }          from 'src/app/models/frame_label';

import { OntologyService }      from 'src/app/services/ontology.service';
import { VideoFrameService }    from 'src/app/services/video_frame.service';
import { FrameLabelingService } from 'src/app/services/labeling.service';

import { utilsConvert }    from 'src/app/utils/utils'

import { DOCUMENT } from '@angular/common';
import * as _ from 'lodash';

@Component({
  selector: 'debug-frames',
  templateUrl: './debug-frames.component.html',
  styleUrls: ['./debug-frames.component.scss']
})

export class DebugFramesComponent implements OnInit {

  frameUsers   : string[] = ["Mal", "Jane", "Inara", "Kaylee"];

  framesReqLimit  : number = 50;
  framesReqOffset : number = 0;

  videoFrames  : VideoFrame[] = [];
  checkedFrames: VideoFrame[] = [];


  @ViewChild('canvas', {static: false}) workCanvas: ElementRef;
  ctx: any;
  ratio : number;


  selectedVideo : number = -1;
  selectedUser  : string = "";

  showDeleted: boolean = false;

  constructor(
    private videoFrameService : VideoFrameService,
    private labelingService   : FrameLabelingService,
  ) { }


  requestOverlay(background: any, frame: number) : void {
    this.ctx.globalAlpha = 1.0;


    let cWidth  = this.workCanvas.nativeElement.width;
    let cHeight = this.workCanvas.nativeElement.height;
    this.ctx.clearRect(0, 0, cWidth, cHeight);


    let hRatio = cWidth  / background.width ;
    let vRatio = cHeight / background.height;
    this.ratio  = Math.min ( hRatio, vRatio );
    this.ctx.drawImage(background, 0,0, background.width, background.height, 0, 0, background.width * this.ratio, background.height * this.ratio);
    //this.ctx.drawImage(background,0,0);

    console.log('Image is displayed!');
    let labels: FrameLabel[];
    this.labelingService.getLabeling(frame)
        .subscribe(data => {
        labels = data;

        console.log(this.workCanvas);
        console.log('We are ready to draw');
        console.log(this.ctx);

        
        for (let label of labels) {
          let color = Number(label.color); /* Should descide on the format */
          let r = Math.floor((color)            ) % 256;
          let g = Math.floor((color / 256)      ) % 256;
          let b = Math.floor((color / 256 / 256)) % 256;

          this.ctx.fillStyle = 'rgb('+ r.toString() + ',' + g.toString() + ',' + b.toString() + ')' ;

          this.ctx.globalAlpha = 0.4;

          this.ctx.fillRect(
              label.p1.x * this.ratio,
              label.p1.y * this.ratio,
              label.p2.x * this.ratio - label.p1.x * this.ratio,
              label.p2.y * this.ratio - label.p1.y * this.ratio
          );

          this.ctx.globalAlpha = 1.0;
          this.ctx.strokeStyle = 'rgb(0,0,0)';
          this.ctx.beginPath();
          this.ctx.rect(
            label.p1.x * this.ratio,
            label.p1.y * this.ratio,
            label.p2.x * this.ratio - label.p1.x * this.ratio,
            label.p2.y * this.ratio - label.p1.y * this.ratio
          );
          this.ctx.stroke();

          this.ctx.strokeStyle = 'rgb(255,255,255)';
          this.ctx.beginPath();
          this.ctx.rect(
            label.p1.x * this.ratio + 1,
            label.p1.y * this.ratio + 1,
            label.p2.x * this.ratio - label.p1.x * this.ratio - 2,
            label.p2.y * this.ratio - label.p1.y * this.ratio - 2
          );
          this.ctx.stroke();


        }
    });

  }

  onOpenFrame(frame: number, url: string) : void {
        console.log("onOpenFrame(): " + frame);

        let background = new Image();
        background.src = url;
        // Make sure the image is loaded first otherwise nothing will draw.
        background.onload = () => this.requestOverlay(background, frame);
  }

  ngOnInit(): void {
    this.refreshFrames();
  }


  onLimitChanged(event : any) {
    this.framesReqLimit = event.target.value;
  }

  onOffsetChanged(event : any) {
    this.framesReqOffset = event.target.value;
  }

  refreshFrames() : void {

    let filters : any [] = [];
    if (this.selectedVideo > 0) {
      filters.push({name: 'video'    , value: this.selectedVideo});
    }
    if (this.selectedUser) {
      filters.push({name: 'user'    , value: this.selectedUser});
    }
    if (this.showDeleted) {
      filters.push({name: 'showDeleted'    , value: this.showDeleted});
    }

    this.videoFrameService.getFramesByFilters(
      filters, 
      [ 
       {name: 'id'            , order: this.sort.id    },
       {name: 'video_id'      , order: this.sort.video },
       {name: 'video_date'    , order: this.sort.video_date    },
       {name: 'creation_date' , order: this.sort.creation_date }       
      ],  this.framesReqLimit, this.framesReqOffset, null)
    .subscribe(data => {
      this.videoFrames = data.data;
      console.log(data);
    });

    this.videoFrameService.getFramesUsers()
    .subscribe(data => {
      this.frameUsers = data;
      console.log(data);
    });

  }

  ngAfterViewInit(): void {
    this.ctx = this.workCanvas.nativeElement.getContext('2d');
  }


  onCheckFrameChanged(file: VideoFrame, event: any) {
    if (event.target.checked) {
      this.checkedFrames.push(file);
    }
    else {
      _.remove(this.checkedFrames, (f) => f === file);
    }
  }

  async deleteCheckedFrames() {
    const error = await this.videoFrameService.deleteFrames(this.checkedFrames);
    if (error) {
      console.error(error);
      alert(error.message);
    }
    this.refreshFrames();
    this.checkedFrames = [];
  }


  sort : any = {
    id            : 1,
    video         : 1,
    video_date    : 1,
    creation_date : 1
  }

  onIdSort() : void
  {
    this.sort.id = (this.sort.id + 1) % 3;
  }

  onVideoSort() : void
  {
    this.sort.video = (this.sort.video + 1) % 3;
  }

  onVideoDateSort() : void
  {
    this.sort.video_date = (this.sort.video_date + 1) % 3;
  }

  onCreationDateSort() : void
  {
    this.sort.creation_date = (this.sort.creation_date + 1) % 3;
  }

  onShowDeletedChanged(event : any) : void {
    this.showDeleted = event.target.checked;
  }

  frameUserSelected(frameUser : string) : void {
    this.selectedUser = frameUser;
  }

  onVideoChanged(event:any) : void {
    this.selectedVideo = event.target.value;
  }


  getSimpleSize    = utilsConvert.getSimpleSize;
  getSimpleDate    = utilsConvert.getSimpleDate;
  getSimpleTime    = utilsConvert.getSimpleTime;
  getColorCode     = utilsConvert.getColorCode;
  getPrintableHash = utilsConvert.getPrintableHash;

}

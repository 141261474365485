import { Action } from '@ngrx/store';
import { CollectionMetadata } from 'src/app/models/collection_metadata';
import UploadedFile from 'src/app/models/uploaded_file';
import { VideoFilters } from 'src/app/models/video_filters';
import { VideoFrame } from 'src/app/models/video_frame';
import { VideoFrameFilters } from 'src/app/models/video_frame_filters';

export enum EContentsTabComponentActions {
	SelectedFramesChanged = '[ContentsTabComponent] Selected Frames Changed',
	SelectedVideosChanged = '[ContentsTabComponent] Selected Videos Changed',
	NavigateToTab = '[ContentsTabComponent] NavigateToTab',
	FramesPageLoadSuccess = '[ContentsTabComponent] Frames page load success',
	NavigateToFramesPage = '[ContentsTabComponent] Navigate to Frames page',
	FramesFiltersChanged = '[ContentsTabComponent] Frames filters changed',
	ToggleFrameFilters = '[ContentsTabComponent] Frame filters togggle',
	VideoPageLoadSuccess = '[ContentsTabComponent] Video page load success',
	NavigateToVideoPage = '[ContentsTabComponent] Navigate to Video page',
	VideoFiltersChanged = '[ContentsTabComponent] Video filters changed',
	ToggleVideoFilters = '[ContentsTabComponent] Video filters togggle'
}

export class SelectedFramesChanged implements Action {
  public readonly type = EContentsTabComponentActions.SelectedFramesChanged;
  constructor ( public frames: number[] ) {}
}

export class SelectedVideosChanged implements Action {
	public readonly type = EContentsTabComponentActions.SelectedVideosChanged;
	constructor ( public videos: number[] ) {}
}

export class NavigateToTab implements Action {
  public readonly type = EContentsTabComponentActions.NavigateToTab;
  constructor(public tabNumber: number) {}
}

export class FramesPageLoadSuccess implements Action {
	public readonly type = EContentsTabComponentActions.FramesPageLoadSuccess;
	constructor(public metadata: CollectionMetadata, public frames: VideoFrame[]) {}
}

export class NavigateToFramesPage implements Action {
	public readonly type = EContentsTabComponentActions.NavigateToFramesPage;
	constructor(public pageNumber: number) {}
}

export class FramesFiltersChanged implements Action {
	public readonly type = EContentsTabComponentActions.FramesFiltersChanged;
	constructor(public filters: VideoFrameFilters) {}
}

export class ToggleFrameFilters implements Action {
	public readonly type = EContentsTabComponentActions.ToggleFrameFilters;
	constructor() {}
}

export class VideoPageLoadSuccess implements Action {
	public readonly type = EContentsTabComponentActions.VideoPageLoadSuccess;
	constructor(public metadata: CollectionMetadata, public videos: UploadedFile[]) {}
}

export class NavigateToVideoPage implements Action {
	public readonly type = EContentsTabComponentActions.NavigateToVideoPage;
	constructor(public pageNumber: number) {}
}

export class VideoFiltersChanged implements Action {
	public readonly type = EContentsTabComponentActions.VideoFiltersChanged;
	constructor(public filters: VideoFilters) {}
}

export class ToggleVideoFilters implements Action {
	public readonly type = EContentsTabComponentActions.ToggleVideoFilters;
	constructor() {}
}

export type ContentsTabComponentActions = 
	SelectedFramesChanged | SelectedVideosChanged | NavigateToTab | FramesPageLoadSuccess | NavigateToFramesPage | FramesFiltersChanged | ToggleFrameFilters | VideoPageLoadSuccess |	NavigateToVideoPage |	VideoFiltersChanged |	ToggleVideoFilters;
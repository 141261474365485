import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { Auth } from 'aws-amplify';
import { Store } from '@ngrx/store';

import { HttpAuthClient } from './http_auth';
import { FrameLabel } from '../models/frame_label';
import { HttpServiceBase } from './http_service_base';
import { AppState } from '../store/state/app.state';

@Injectable({
  providedIn: 'root'
})
export class FrameLabelingService extends HttpServiceBase {
  constructor(
    private http: HttpClient,
    private httpAuth: HttpAuthClient,
    protected store : Store<AppState>) {
      super(store);
    }

  getLabeling(frame_id : number) : Observable<FrameLabel[]> {
    // @ts-ignore
    let params = new HttpParams();
    params = params.append("frame", frame_id.toString());
    return this.httpAuth.get<FrameLabel[]>(`${this.serverUrl}/api/frame_labels/`, {params : params});
  }

  getLabelingForMultipleFrames(frame_ids : number[]) : Observable<FrameLabel[]> {
    // @ts-ignore
    let params = new HttpParams();
    params = params.append("frames", frame_ids.join(','));
    return this.httpAuth.get<FrameLabel[]>(`${this.serverUrl}/api/frame_labels/multiple/`, {params : params});
  }

  createLabel(label: FrameLabel) : Observable<FrameLabel>
  {
    return from(Auth.currentAuthenticatedUser()).pipe(
      map(currentUser => ({
        ...label,
        user : currentUser.username
      })),
      switchMap(label =>
        this.httpAuth.post<FrameLabel[]>(
          `${this.serverUrl}/api/frame_labels/create`,
          label,
          {
            headers: {
              'Content-Type': 'application/json'
            }
          })),
      map(labels => ({
        ...label,
        id: labels.length > 0 ? labels[0].id : null
      }))
    );
  }

  async deleteLabel(id?: number) {
    if (id == null) {
      console.log("deleteLabeling: no id");
      return;
    }
    await this.httpAuth.delete(`${this.serverUrl}/api/frame_labels/${id}`, {}).toPromise();
  }

}

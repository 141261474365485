import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { VideoFrameStatus } from 'src/app/models/video_frame';
import { VideoFrameFilters, VideoFrameSortColumn } from 'src/app/models/video_frame_filters';

@Component({
  selector: 'filters-panel',
  templateUrl: './filters-panel.component.html',
  styleUrls: ['./filters-panel.component.scss']
})
export class FiltersPanelComponent implements OnInit, OnChanges {
  @Input("state") state : VideoFrameFilters;
  @Output("onChange") onChange = new EventEmitter<VideoFrameFilters>();

  sortSelectValue : string;
  searchValue: string;
  isFiltersShown: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.sortSelectValue = `${this.state.sortColumn},${this.state.sortOrder}`;
    this.searchValue = this.state.searchQuery;
  }

  onSortSelected(value: string) {
    const parts = value.split(',');
    const newState = {
      ...this.state,
      sortColumn: <VideoFrameSortColumn>parts[0],
      sortOrder: parseInt(parts[1])
    };
    this.onChange.emit(newState);
  }

  onSearch() {
    const newState = {
      ...this.state,
      searchQuery: this.searchValue
    };
    this.onChange.emit(newState);
  }

  onStatusClick(status?: VideoFrameStatus) {
    const newState = {
      ...this.state,
      statusFilter: status
    };
    this.onChange.emit(newState);
  }

  toggleFilters() {
    this.isFiltersShown = !this.isFiltersShown;
  }

}

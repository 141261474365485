import { Injectable } from '@angular/core';
import { HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import { Store } from '@ngrx/store';
import { Auth } from 'aws-amplify';

import { HttpAuthClient } from './http_auth';
import Tag                  from '../models/tag';
import UploadedFile         from '../models/uploaded_file';
import { HttpServiceBase } from './http_service_base';
import { AppState } from '../store/state/app.state';

@Injectable({
  providedIn: 'root'
})
export class TagService extends HttpServiceBase {
  private cache : Tag[][] = [];

  constructor(
    private httpAuth: HttpAuthClient,
    protected store : Store<AppState>
  ) {
    super(store);
  }

  invalidateCache() {
    this.cache = [];
  }

  getTags(prefix: string, count: number) : Observable<Tag[]> {
    const cacheKey = (prefix ? prefix : '') + count;
    if (cacheKey in this.cache) {
      return Observable.of(this.cache[cacheKey]);
    }

    // @ts-ignore
    let params = new HttpParams();
    if (count != undefined && count !== 0) {
      params = params.append("count", count.toString());
    }

    if (prefix != undefined && prefix !== "") {
      params = params.append("prefix", prefix);
    }

    return this.httpAuth
    .get<any[]>(`${this.serverUrl}/api/tags/`,  {params : params})
    .map<any[], Tag[]>(data => {
      const updatedData = data.map(tag => ({
        ...tag,
        ...{
          color: '#' + tag.color.toString(16).padStart(6, '0') // number to hex string
        }
      }))
      this.cache[cacheKey] = updatedData;
      return updatedData;
    });
  }

  getTagsByIds(ids : number[]) : Observable<Tag[]> {
    // @ts-ignore
    let params = new HttpParams();
    let request = ids.map( id => id.toString()).join(',')

    params = params.append("ids", request);
        
    return this.httpAuth.get<Tag[]>(`${this.serverUrl}/api/tagids`,  {params : params});
  }

  getVideos(tag_id: number) : Observable<UploadedFile[]> {
     // @ts-ignore
     let params = new HttpParams();
     params = params.append("tag_id", tag_id.toString());
     return this.httpAuth.get<UploadedFile[]>(`${this.serverUrl}/api/tags/videos`,  {params : params});
  }

  createTag(name: string, color: number): Promise<HttpErrorResponse> {
    this.cache = [];
    try {
      Auth.currentAuthenticatedUser()
        .then(async currentUser => {
          console.log("createTag", name);
          await this.httpAuth.post(
          `${this.serverUrl}/api/tags/create`,
          {
            name : name,
            color : color,
            user : currentUser.username
          },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }).toPromise();

        });
    }
    catch(e) {
      return e;
    }
    return null;

  }
}

import { AppState } from "../state/app.state";
import { createSelector } from '@ngrx/store';
import { Notification } from 'src/app/models/notification';
import * as _ from 'lodash';

const getNotifications = (state: AppState) => state.notifications;

export const selectNotifications = createSelector(
  getNotifications,
  (notifications : Record<number, Notification>) => _.values(notifications)
)
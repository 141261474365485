import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActionsService } from 'src/app/services/actions.service';
import { Actor, ActorTarget } from 'src/app/models/actor';
import { Observable, Subscription } from 'rxjs';
import { VideoFrame } from 'src/app/models/video_frame';
import UploadedFile from 'src/app/models/uploaded_file';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/store/state/app.state';
import { selectSelectedVideos, selectSelectedFrames } from 'src/app/store/selectors/contents-tab-component.selectors';
import { ShowNotification } from 'src/app/store/actions/notifications.actions';
import { NotificationType } from 'src/app/models/notification';
import { Basket, BasketType } from 'src/app/models/basket';
import { selectFrameBaskets } from 'src/app/store/selectors/basket-component.selectors';
import { Job, JobStatus } from 'src/app/models/job';
import { CreateJob } from 'src/app/store/actions/jobs.actions';
import { Create, ECollectionItemType } from 'src/app/store/actions/collection.actions';
import { BasketsSelectors } from 'src/app/store/selectors/baskets.selectors';
import { NavigateToTab } from 'src/app/store/actions/contents-tab-component.actions';
import { utilsAuth } from 'src/app/services/utils_auth';

enum SupportedActionTargets {
  Videos = 'videos',
  Frames = 'frames',
  FrameBasket = 'frame-basket'
}

@Component({
  selector: 'actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent implements OnInit, OnDestroy {
  @Input("target") target : SupportedActionTargets;

  createJobActionKey = 'create_job';
  manualCutterActionKey = 'manual_cut';

  groups: string[] = [];
  actions: Actor[] = [];
  selectedAction: Actor | string = null;
  private subscriptions: Subscription[] = [];
  private checkedVideos: UploadedFile[];
  private checkedFrames: VideoFrame[];
  private selectedFrameBaskets: Basket[];
  description : string = "";
  email : string = null;

  private checkedVideos$: Observable<UploadedFile[]> = this.store.pipe(select(selectSelectedVideos));
  private checkedFrames$: Observable<VideoFrame[]> = this.store.pipe(select(selectSelectedFrames));
  private selectedFrameBaskets$ : Observable<Basket[]> = this.store.pipe(select(selectFrameBaskets));
  private frameBaskets$: Observable<Basket[]> = 
    this.store.pipe(select(BasketsSelectors.instance.selectVideoFrameBasketsList));

  constructor(
    private actionsService : ActionsService,
    private store : Store<AppState>) { }

  ngOnInit() {
    if (this.target === SupportedActionTargets.Videos) {
      this.subscriptions.push(this.actionsService.getActors(ActorTarget.Video, false).subscribe(actions => {
        this.actions = actions;
      }));
      this.subscriptions.push(this.checkedVideos$.subscribe(videos => {
        this.checkedVideos = videos;
      }));
    }
    else if (this.target === SupportedActionTargets.Frames) {
      this.subscriptions.push(this.actionsService.getActors(ActorTarget.Frame, false).subscribe(actions => {
        this.actions = actions;
      }));
      this.subscriptions.push(this.checkedFrames$.subscribe(frames => {
        this.checkedFrames = frames;
      }));
    }
    else if (this.target === SupportedActionTargets.FrameBasket) {
      this.subscriptions.push(this.actionsService.getActors(ActorTarget.Frame, false).subscribe(actions => {
        this.actions = actions;
      }));
      this.subscriptions.push(
        this.selectedFrameBaskets$.subscribe(basket => {
          this.selectedFrameBaskets = basket;
        })
      );
    } 

    utilsAuth.getGroups().then(data => {
      this.groups = data;
      console.log("groups: ", this.groups);
    });
  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  startAction() {
    console.log(this.selectedAction);

    if (this.selectedAction) {
      if (this.selectedAction instanceof Object) {
        switch (this.target) {
          case SupportedActionTargets.Videos:
            this.startVideosBackendAction(this.selectedAction as Actor);
            break;
          case SupportedActionTargets.Frames:
            this.startFramesBackendAction(this.selectedAction as Actor);
            break;
          case SupportedActionTargets.FrameBasket:
            break;
          default:
            console.log('Error. actions.component: startAction: Unsupported action target:', this.target);
            break;
        }
      }
      else if (this.selectedAction == this.createJobActionKey) {
        switch(this.target) {
          case SupportedActionTargets.FrameBasket:
            this.createJobForSelectedFrameBasket();
            break;
          case SupportedActionTargets.Frames:
            this.createJobForFrames();
            break;
        }
      }
      else if (this.selectedAction == this.manualCutterActionKey) {
        if (this.target == "videos") {
          this.store.dispatch(new NavigateToTab(5));
        }
      }
    }
    else {
      this.store.dispatch(new ShowNotification("Select an action", NotificationType.Information));
    }
  }

  startVideosBackendAction(action : Actor) {
    if(this.checkedVideos.length == 0) {
      this.store.dispatch(new ShowNotification("Select at least one video", NotificationType.Information));
    }
    else {
      this.actionsService
      .startVideoActions(action, this.checkedVideos)
      .subscribe(
        (res) => {
          this.store.dispatch(new ShowNotification("Action is created", NotificationType.Success))
        },
        (error) => {
          console.error(error);
          alert(error.message);
        })
    }
  }

  startFramesBackendAction(action : Actor) {
    if(this.checkedFrames.length == 0) {
      this.store.dispatch(new ShowNotification("Select at least one frame", NotificationType.Information));
    }
    else {
      this.actionsService
      .startFrameActions(action, this.checkedFrames)
      .subscribe(
        (res) => {
          this.store.dispatch(new ShowNotification("Action is created", NotificationType.Success))
        },
        (error) => {
          console.error(error);
          alert(error.message);
        })
    }
  }

  createJobForSelectedFrameBasket() {
    if (this.selectedFrameBaskets) {
      this.createJobForFrameBasket(this.selectedFrameBaskets[0]);
    }
    else {
      this.store.dispatch(new ShowNotification("Basket is not selected", NotificationType.Information));
    }
  }

  createJobForFrameBasket(basket: Basket) {
    const newJob : Job = {
      id: null,
      frame_basket_id: basket.id,
      description: this.description,
      status: JobStatus.New,
      assigned_to: (!this.email || this.email.trim().length == 0) ? null : this.email 
    }
    this.store.dispatch(new CreateJob(newJob));
  }

  createJobForFrames() {
    if (this.checkedFrames.length == 0) {
      this.store.dispatch(new ShowNotification("Select at least one frame", NotificationType.Information));
    }
    else {
      this.store.dispatch(new ShowNotification("Creating job...", NotificationType.Information));

      const uss = this.frameBaskets$.subscribe(baskets => {
        const foundBasket = baskets.find(b => b.name == this.description);
        if (foundBasket) {
          uss.unsubscribe();

          this.createJobForFrameBasket(foundBasket);
        }
      })

      const newBasket : Basket = {
        name: this.description,
        type: BasketType.VideoFrames,
        videoFrameIds: this.checkedFrames.map(f => f.id)
      };
      this.store.dispatch(new Create(newBasket, ECollectionItemType.Basket));
    }
  }
}

import { Injectable } from '@angular/core';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { LoadConfiguration, EConfigurationActions, LoadConfigurationSuccess } from '../actions/configuration.actions';
import { of } from 'rxjs';
import { switchMap} from 'rxjs/operators';
import { Configuration } from '../../models/configuration';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Injectable()
export class ConfigurationEffects {
	constructor(
		private configurationService: ConfigurationService,
		private actions$: Actions
	) {}

	@Effect()
	getConfiguration$ = this.actions$.pipe(
		ofType<LoadConfiguration>(EConfigurationActions.LoadConfiguration),
		switchMap(() => this.configurationService.getConfiguration()),
		switchMap((configuration: Configuration) => of(new LoadConfigurationSuccess(configuration)))
	)
}
import { VideoFrame } from './video_frame';
import UploadedFile from './uploaded_file';
import { IWithId } from './i_with_id';
import ObjectOntologyClass from './object_ontology_class';

export enum BasketType {
  Videos = 0,
  VideoFrames = 1,
  Classes = 2
}

export class Basket implements IWithId {
  id?            : number;
  name           : string;
  user_id?       : string;
  type           : BasketType;
  videoFrameIds? : number[];
  videoFrames?   : VideoFrame[];
  videoIds?      : number[];
  videos?        : UploadedFile[];
  classIds?      : number[];
  classes?       : ObjectOntologyClass[];
}


import { Injectable } from '@angular/core';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { switchMap, map } from 'rxjs/operators';
import { UploadsService } from 'src/app/services/uploads.service';
import UploadedFile from 'src/app/models/uploaded_file';
import { Load, ECollectionActions, LoadSuccess, ECollectionItemType, LoadPage } from '../actions/collection.actions';
import { VideoPageLoadSuccess } from '../actions/contents-tab-component.actions';

@Injectable()
export class VideosEffects {
	constructor(
		private videosService: UploadsService,
		private actions$: Actions
	) {}

	@Effect()
	getVideos$ = this.actions$.pipe(
		ofType<Load<UploadedFile>>(ECollectionActions.Load),
		switchMap(() => this.videosService.getUploads()),
		switchMap((videos: UploadedFile[]) => [
			new LoadSuccess(videos, ECollectionItemType.Video)
		])
	)

	@Effect()
	getVideosPage$ = this.actions$.pipe(		
		ofType<LoadPage<UploadedFile>>(ECollectionActions.LoadPage),
		switchMap((action) => {
			return this.videosService.getVideosByFilters(
				action.filters,
				[{ name: action.sort, order: action.sortOrder }],
				action.limit,
				action.offset,
				action.search)
			.pipe(map(videos => ({ videos, action })))
		}),
		switchMap(({videos, action}) => {
			console.log("getvideospage result", videos);
			return [
			new LoadSuccess<UploadedFile>(videos.data, ECollectionItemType.Video),
			new VideoPageLoadSuccess(videos.metadata, videos.data)
		]})
	)
}
import Tag from './tag';

export enum VideoFrameStatus {
    None = 0,
    InProgress = 1,
    Classified = 2,
    Segmented = 3,
    Issue = 4
}

export const videoFrameStatusToStr = (status: VideoFrameStatus) => {
    switch(status) {
        case VideoFrameStatus.None: return "None";
        case VideoFrameStatus.InProgress: return "InProgress";
        case VideoFrameStatus.Classified: return "Classified";
        case VideoFrameStatus.Segmented: return "Segmented";
        case VideoFrameStatus.Issue: return "Issue";
    }
}

export class VideoFrame {
    id: number;
    name: string;
    user_id : string;
    video_id : number;
    timestamp : number; /* Timestamp inside oridinal view */
    url : string;
    thumbnail_url : string;
    height: number;
    width: number;
    deleted : boolean;
    tags: Tag[] = [];
    comments?: string;
    status: VideoFrameStatus;

    created_by   : string;
    creation_date: string;
    video_date   : string;
}

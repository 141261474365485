import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild, ElementRef, AfterViewInit, EventEmitter } from '@angular/core';
import UploadedFile from 'src/app/models/uploaded_file';
import { ContentsItem, ConstentsItemType } from 'src/app/models/contents_item';
import { VideoFrame } from 'src/app/models/video_frame';
import { VideoFrameService } from 'src/app/services/video_frame.service';
import * as _ from 'lodash';
import * as moment from 'moment';

const PREVIEW_INTERVAL = 1.0;
const PREVIEW_COUNT = 11;
const PREVIEW_WIDTH = 96;

@Component({
  selector: 'cutter',
  templateUrl: './cutter.component.html',
  styleUrls: ['./cutter.component.scss']
})
export class CutterComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() video: UploadedFile;
  @Input() frames: VideoFrame[];
  @Input() videoElementContainer: { el };
  @Input() onDelete = new EventEmitter();

  @ViewChild('videoPlayer', {static: false}) videoPlayer: ElementRef;
  @ViewChild('videoCanvas', {static: false}) videoCanvas: ElementRef;
  @ViewChild('shadowVideoPlayer', {static: false}) shadowVideoPlayer: ElementRef;

  frameItems : ContentsItem[] = []
  previews: string[] = [];
  times: number[] = [];
  previewBeingRendered?: number = null;
  
  private changesCheckInterval = null;

  constructor(private videoFrameService: VideoFrameService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.video) {
      this.previews = Array(PREVIEW_COUNT).fill(this.video.thumbnail_url);
    } else {
      this.previews = [];
    }

    this.frameItems = this.frames.map(frame => ({
      id: frame.id,
      title: null,
      url: frame.url,
      thumbnail_url       : !!frame.thumbnail_url ? frame.thumbnail_url : frame.url,
      thumbnail_hover_url : !!frame.thumbnail_url ? frame.thumbnail_url : frame.url,
      type: ConstentsItemType.Frame,
      checked: false,
      group_key: moment(frame.timestamp).format('YYYY-MM-DD')
    }));

    if (this.changesCheckInterval) {
      clearInterval(this.changesCheckInterval);
    }
    this.changesCheckInterval = setInterval(() => {
      if (this.frames && this.frameItems.length != this.frames.length) {
        this.frameItems = this.frames.map(frame => ({
          id: frame.id,
          title: null,
          url: frame.url,
          thumbnail_url       : !!frame.thumbnail_url ? frame.thumbnail_url : frame.url,
          thumbnail_hover_url : !!frame.thumbnail_url ? frame.thumbnail_url : frame.url,
          type: ConstentsItemType.Frame,
          checked: false,
          group_key: moment(frame.timestamp).format('YYYY-MM-DD')
        }));
      }
    }, 500);
  }

  ngOnInit() {
    this.onDelete.subscribe(() => { this.deleteFrames(); });
  }

  ngAfterViewInit(): void {
    this.videoElementContainer.el = this.videoPlayer.nativeElement;
  }

  onShadowVideoSeeked() {
    const canvas = this.videoCanvas.nativeElement;
    const video = this.shadowVideoPlayer.nativeElement;
    const height = Math.floor(PREVIEW_WIDTH * video.videoHeight / video.videoWidth);
    video.width = PREVIEW_WIDTH;
    canvas.width = PREVIEW_WIDTH;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(video, 0, 0, PREVIEW_WIDTH, height);
    const imageSrc = canvas.toDataURL();
    this.previews[this.previewBeingRendered] = imageSrc;
    this.previewBeingRendered = null;
  }

  updatePreviews() {
    const currentTime = this.videoPlayer.nativeElement.currentTime;
    const oneDirectionNumber = Math.floor(PREVIEW_COUNT / 2);
    this.times = []
      .concat(
        ... _.range(currentTime - oneDirectionNumber * PREVIEW_INTERVAL, currentTime, PREVIEW_INTERVAL),
        ... _.range(currentTime, currentTime + (oneDirectionNumber + 1) * PREVIEW_INTERVAL, PREVIEW_INTERVAL)
      )
      .map(time => time >= 0 && time < this.videoPlayer.nativeElement.duration ? time: null);

    let i = -1;
    let atLeastOneStarted = false;
    const interval = setInterval(() => {
      if (atLeastOneStarted && this.previewBeingRendered !== null) {
        // Other preview is still rendering. We need to wait a bit more.
        return;
      }

      i = i + 1;
      if (i >= this.times.length) {
        // All previews are rendered.
        clearInterval(interval);
        return;
      }

      if (this.times[i] === null) {
        this.previews[i] = null;
        return;
      }

      atLeastOneStarted = true;
      this.previewBeingRendered = i;
      this.shadowVideoPlayer.nativeElement.currentTime = this.times[i];
    }, 100);
  }

  onPreviewClick(idx: number) {
    this.videoPlayer.nativeElement.currentTime = this.times[idx];
    this.updatePreviews();
  }

  async deleteFrames() {
    const checkedItems = this.frameItems.filter(frame => frame.checked);
    const checkedFrames = this.frames.filter(frame => 
      checkedItems.findIndex(item => item.id === frame.id) >= 0);
    await this.videoFrameService.deleteFrames(checkedFrames);
    for (let frame of checkedFrames) {
      const index = this.frames.indexOf(frame);
      if (index >= 0 ) {
        this.frames.splice(index, 1);
      }
    }
  }


}
